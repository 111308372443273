<template>
  <div>
    <header class="header_container flex items-center px-6 py-2.5">
      <nav class="p-0 flex gap-5 justify-between items-center w-full">
        <div class="flex">
          <div class="main-logo mx-2 align-self-center">
            <router-link to="/">
              <img src="/assets/gdc-logo.png" alt="" width="200px">
            </router-link>
          </div>
          <div class="navbar d-none d-lg-flex">
            <ul class="flex gap-4 items-center mb-0 pl-0">
              <li>
                <div class="inline-flex rounded shadow-sm" role="group">
                  <button type="button"
                    :class="{ 'bg-pinkHover text-white': activeTab === 'exchange', 'bg-gray-850': activeTab !== 'exchange' }"
                    @click="activeTab = 'exchange'"
                    class="px-3 py-0.5 font-medium text-whiter text-12 border-pinkHover rounded-s-sm focus:z-10 focus:text-white dark:bg-gray-850 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">
                    Exchange
                  </button>
                  <button type="button"
                    :class="{ 'bg-pinkHover ': activeTab === 'web', 'bg-gray-850': activeTab !== 'web' }"
                    @click="activeTab = 'web'" disabled
                    class="px-3 py-0.5 font-medium text-12 border-pinkHover rounded-e-sm focus:z-10  dark:bg-gray-850 dark:border-gray-700 dark:hover:text-theme-color dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-theme-color">
                    Web3
                  </button>
                </div>
              </li>
             <li class="group">
                <div class="relative inline-block text-left" @click="openDropdown(menu.id)">
                  <span 
                    class="cursor-pointer text-theme-color text-sm flex items-center gap-1 group-hover:text-pinkHover font-medium">
                    {{ menu.text }}
                    <i class="pi pi-sort-down-fill transition-all duration-500 ease-in-out group-hover:text-pinkHover group-hover:rotate-185 text-7 text-theme-color"
                      :id="'menu-button-' + menu.id" aria-expanded="true" aria-haspopup="true"></i>
                </span>

                  <dropdown :id="menu.id"
                    class="hide-dropdown absolute top- right-50 z-10 mt-2 w-96 origin-top-left rounded bg-white shadow-lg ring-1 py-3 ring-black ring-opacity-5 focus:outline-none hidden"
                    role="menu" aria-orientation="vertical" :aria-labelledby="'menu-button-' + menu.id" tabindex="-1">
                    <div class="px-3" role="none">
                      <router-link v-for="(item, index) in menu.items" :key="index" :to="item.url"
                        class="group/img hover:bg-bglightpink  rounded transition-all p-1 flex items-center">
                        <img :src="item.icon" alt="" class="w-12 mr-4" />
                        <div class="flex flex-col w-full">
                          <p class="text-14 font-semibold opacity-85 flex items-center justify-between mb-0">{{
                            item.text
                          }}<span><img src="/assets/referral/right-arrow.svg" alt="" width="16"
                                class="opacity-0 group-hover/img:opacity-100 ml-5"></span></p>
                          <p class="text-14 text-graylight">{{ item.description }}</p>
                        </div>
                      </router-link>
                    </div>
                  </dropdown>
                </div>
              </li>
              <li class="group flex ">
                <div class="relative inline-block text-left" @click="openDropdown(trademenu.id)">
                  <span
                    class="cursor-pointer text-theme-color text-sm flex items-center gap-1 group-hover:text-pinkHover font-medium mt-1">
                    {{ trademenu.text }}
                    <i class="pi pi-sort-down-fill text-theme-color transition-all duration-500 ease-in-out group-hover:text-pinkHover group-hover:rotate-185 text-7 text-theme-color"
                      :id="'menu-button-' + trademenu.id" aria-expanded="true" aria-haspopup="true"></i>
                </span>

                  <dropdown :id="trademenu.id"
                    class="absolute top- left-0 z-10 mt-2 w-96 origin-top-left rounded bg-white shadow-lg ring-1 py-3 ring-black ring-opacity-5 focus:outline-none hidden"
                    role="menu" aria-orientation="vertical" :aria-labelledby="'menu-button-' + menu.id" tabindex="-1">
                    <div class="px-3" role="none">
                      <router-link v-for="(item, index) in trademenu.items" :key="index" :to="item.url"
                        class="group/img hover:bg-bglightpink p-1 rounded transition-all flex items-center">
                        <img :src="item.icon" alt="" class="w-12 mr-4" />
                        <div class="flex flex-col w-full">
                          <p class="text-14 font-semibold opacity-85 flex items-center justify-between mb-0">{{
                            item.text
                          }}<span><img src="/assets/referral/right-arrow.svg" alt="" width="16"
                                class="opacity-0 group-hover/img:opacity-100 ml-5"></span></p>
                          <p class="text-14 text-graylight">{{ item.description }}</p>
                        </div>
                      </router-link>
                    </div>
                  </dropdown>
                </div>
              </li>
              <li v-for="(dropdown, id) in groupmenu" :key="id" class="group hidden">
                <div class="relative inline-block text-left" @click="openDropdown(id)">
                  <span
                    class="cursor-pointer text-theme-color text-sm flex items-center gap-1 group-hover:text-pinkHover font-medium">
                    {{ dropdown.text }}
                    <i class="pi pi-sort-down-fill transition-all duration-500 ease-in-out group-hover:text-pinkHover group-hover:rotate-185 text-7 text-theme-color"
                      :id="'menu-button-' + id" aria-expanded="true" aria-haspopup="true"></i>
                </span>

                  <dropdown :id="id"
                    class="hide-dropdown absolute top- right-50 z-10 mt-2 w-96 origin-top-left rounded bg-white shadow-lg ring-1 py-3 ring-black ring-opacity-5 focus:outline-none hidden"
                    role="menu" aria-orientation="vertical" :aria-labelledby="'menu-button-' + id" tabindex="-1">
                    <div class="px-3" role="none">
                      <router-link :to="item.url" v-for="(item, index) in dropdown.items" :key="index"
                        class="hover:bg-bglightpink py-4 rounded transition-all px-4 flex items-center group/img">
                        <img :src="item.icon" alt="" class="w-6 mr-4" />
                        <div class="flex flex-col w-full">
                          <p class="text-14 font-semibold opacity-85 flex items-center justify-between">{{ item.text }}
                            <span><img src="/assets/referral/right-arrow.svg" alt="" width="16"
                                class="opacity-0 group-hover/img:opacity-100 ml-5"></span>
                          </p>
                          <p class="text-14 text-graylight">{{ item.description }}</p>
                        </div>
                      </router-link>
                    </div>
                  </dropdown>
                </div>
              </li>
              <li v-for="botmenuItem in botmenuItems" :key="botmenuItem.id" class="text-center group hidden">
                <div class="relative inline-block text-left" @click="openDropdown(botmenuItem.id)">
                  <span
                    class="cursor-pointer text-theme-color text-sm flex items-center gap-1.5 group-hover:text-pinkHover font-medium">Finance
                    <i class="pi pi-sort-down-fill transition-all duration-500 ease-in-out group-hover:text-pinkHover group-hover:rotate-185 text-7 text-theme-color"
                      :id="botmenuItem.id + '-button'" :aria-expanded="dropdownClass === 'hidden' ? 'false' : 'true'"
                      aria-haspopup="true"></i>
                </span>
                  <dropdown :id="botmenuItem.id" :class="dropdownClass" role="menu"
                    :aria-orientation="dropdownOrientation" :aria-labelledby="botmenuItem.id"
                    :tabindex="dropdownTabindex">
                    <div class="flex justify-between">
                      <div class="px-3 mb-4 flex-1" v-for="item in botmenuItem.items" :key="item.category">
                        <p class="text-12 text-graylight ml-6 mb-2">{{ item.category }}</p>
                        <div class="" v-for="option in item.options" :key="option.title">
                          <a class="hover:bg-bglightpink py-4 rounded transition-all px-4 flex items-center mt-2 group">
                            <img :src="option.imageSrc" alt="" class="w-6 mr-4" />
                            <div class="flex flex-col w-full">
                              <p class="text-14 font-semibold opacity-85 flex items-center justify-between">{{
                                option.title }} <span><img src="/assets/referral/right-arrow.svg" alt="" width="16"
                                    class="opacity-0 group-hover:opacity-100 ml-5"></span></p>
                              <p class="text-14 text-graylight">{{ option.description }}</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </dropdown>
                </div>
              </li>
              <li v-for="botmenuItem in botmenuItems" :key="botmenuItem.id" class="text-center group hidden">
                <div class="relative inline-block text-left" @click="openDropdown(botmenuItem.id)">
                  <span
                    class="cursor-pointer text-theme-color text-sm flex items-center gap-1.5 group-hover:text-pinkHover font-medium">Bots
                    <i class="pi pi-sort-down-fill transition-all duration-500 ease-in-out group-hover:text-pinkHover group-hover:rotate-185 text-7 text-theme-color"
                      :id="botmenuItem.id + '-button'" :aria-expanded="dropdownClass === 'hidden' ? 'false' : 'true'"
                      aria-haspopup="true"></i>
                  </span>
                  <dropdown :id="botmenuItem.id" :class="dropdownClass" role="menu"
                    :aria-orientation="dropdownOrientation" :aria-labelledby="botmenuItem.id"
                    :tabindex="dropdownTabindex">
                    <div class="flex justify-between">
                      <div class="px-3 mb-4 flex-1" v-for="item in botmenuItem.items" :key="item.category">
                        <p class="text-12 text-graylight ml-6 mb-2">{{ item.category }}</p>
                        <div class="" v-for="option in item.options" :key="option.title">
                          <a class="hover:bg-bglightpink py-4 rounded transition-all px-4 flex items-center mt-2 group">
                            <img :src="option.imageSrc" alt="" class="w-6 mr-4" />
                            <div class="flex flex-col w-full">
                              <p class="text-14 font-semibold opacity-85 flex items-center justify-between">{{
                                option.title }} <span><img src="/assets/referral/right-arrow.svg" alt="" width="16"
                                    class="opacity-0 group-hover:opacity-100 ml-5"></span></p>
                              <p class="text-14 text-graylight">{{ option.description }}</p>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </dropdown>
                </div>
              </li>
              <li class="group flex hidden">
                <div class="relative inline-block text-left" @click="openDropdown(copymenu.id)">
                  <span
                    class="cursor-pointer text-theme-color text-sm flex items-center gap-1 group-hover:text-pinkHover font-medium mt-1">
                    <img src="/assets/home/fire.svg" alt="" class="size-6" /> {{ copymenu.text }}
                    <i class="pi pi-sort-down-fill transition-all duration-500 ease-in-out group-hover:text-pinkHover group-hover:rotate-185 text-7 text-theme-color"
                      :id="'menu-button-' + copymenu.id" aria-expanded="true" aria-haspopup="true"></i>
                </span>

                  <dropdown :id="copymenu.id"
                    class="absolute top- left-0 z-10 mt-2 w-96 origin-top-left rounded bg-white shadow-lg ring-1 py-3 ring-black ring-opacity-5 focus:outline-none hidden"
                    role="menu" aria-orientation="vertical" :aria-labelledby="'menu-button-' + menu.id" tabindex="-1">
                    <div class="px-3" role="none">
                      <a v-for="(item, index) in copymenu.items" :key="index" :href="item.url"
                        class="group/img hover:bg-bglightpink py-4 rounded transition-all px-4 flex items-center">
                        <img :src="item.icon" alt="" class="w-6 mr-4" />
                        <div class="flex flex-col w-full">
                          <p class="text-14 font-semibold opacity-85 flex items-center justify-between">{{ item.text
                            }}<span><img src="/assets/referral/right-arrow.svg" alt="" width="16"
                                class="opacity-0 group-hover/img:opacity-100 ml-5"></span></p>
                          <p class="text-14 text-graylight">{{ item.description }}</p>
                        </div>
                      </a>
                    </div>
                  </dropdown>
                </div>
              </li>
              <li class="group hidden">
                <div class="relative inline-block text-left" @click="openDropdown('dropdown8')">
                  <span
                    class="cursor-pointer text-theme-color text-sm flex items-center gap-1 group-hover:text-pinkHover font-medium">Institutional
                    <i class="pi pi-sort-down-fill transition-all duration-500 ease-in-out group-hover:text-pinkHover group-hover:rotate-185 text-7 cursor-pointer"
                      id="menu-button" aria-expanded="true" aria-haspopup="true"></i>
                </span>

                  <dropdown id="dropdown8"
                    class="hide-dropdown absolute top- right-50 z-10 mt-2 w-100 origin-top-left rounded bg-white shadow-lg ring-1 py-3 ring-black ring-opacity-5 focus:outline-none hidden min-w-850 -left-96"
                    role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                    <div class="flex justify-between">
                      <div class="px-3 mb-4 flex-1">
                        <a class=" hover:bg-bglightpink py-4 rounded transition-all px-4 flex items-center">
                          <img src="/assets/home/bitcoin-spot.png" alt="" class="w-6 mr-4" />
                          <div class="flex flex-col w-full">
                            <p class="text-14 font-semibold opacity-85">Institutional & VIP Home</p>
                            <p class="text-14 text-graylight">Professional digital asset
                              solutions for institutions</p>
                          </div>
                        </a>
                        <a class=" hover:bg-bglightpink py-4 rounded transition-all px-4 flex items-center">
                          <img src="/assets/home/bitcoin-spot.png" alt="" class="w-6 mr-4 " />
                          <div class="flex flex-col w-full">
                            <p class="text-14 font-semibold opacity-85">Liquidity Program</p>
                            <p class="text-14 text-graylight">Design for market makers to
                              enjoy attractive rates</p>
                          </div>
                        </a>
                        <a class=" hover:bg-bglightpink py-4 rounded transition-all px-4 flex items-center">
                          <img src="/assets/home/bitcoin-spot.png" alt="" class="w-6 mr-4 " />
                          <div class="flex flex-col w-full">
                            <p class="text-14 font-semibold opacity-85">VIP Loan</p>
                            <p class="text-14 text-graylight">Large loans are offered for
                              VIP customers</p>
                          </div>
                        </a>
                        <a class=" hover:bg-bglightpink py-4 rounded transition-all px-4 flex items-center">
                          <img src="/assets/home/bitcoin-spot.png" alt="" class="w-6 mr-4 " />
                          <div class="flex flex-col w-full">
                            <p class="text-14 font-semibold opacity-85">Custody and Asset Management</p>
                            <p class="text-14 text-graylight">Enhance institutional digital
                              asset management</p>
                          </div>
                        </a>
                        <a class=" hover:bg-bglightpink py-4 rounded transition-all px-4 flex items-center">
                          <img src="/assets/home/bitcoin-spot.png" alt="" class="w-6 mr-4 " />
                          <div class="flex flex-col w-full">
                            <p class="text-14 font-semibold opacity-85">Global Market Maker Program</p>
                            <p class="text-14 text-graylight">
                              Participate in the global market maker program, obtain a
                              high-interest-free loan quota and earn substantial bonuses.
                            </p>
                          </div>
                        </a>
                      </div>
                      <div class="px-6 mb-4 flex-1">
                        <a class=" hover:bg-bglightpink py-4 rounded transition-all px-4 flex items-center mt-2">
                          <img src="/assets/home/bitcoin-spot.png" alt="" class="w-6 mr-4 " />
                          <div class="flex flex-col w-full">
                            <p class="text-14 font-semibold opacity-85">Broker Program</p>
                            <p class="text-14 text-graylight">Benefit from integrating with
                              the Gate.io Broker Program</p>
                          </div>
                        </a>
                        <a class=" hover:bg-bglightpink py-4 rounded transition-all px-4 flex items-center">
                          <img src="/assets/home/bitcoin-spot.png" alt="" class="w-6 mr-4 " />
                          <div class="flex flex-col w-full">
                            <p class="text-14 font-semibold opacity-85">OTC & Execution Services</p>
                            <p class="text-14 text-graylight">OTC trading and execution
                              solutions</p>
                          </div>
                        </a>
                        <a class=" hover:bg-bglightpink py-4 rounded transition-all px-4 flex items-center">
                          <img src="/assets/home/bitcoin-spot.png" alt="" class="w-6 mr-4 " />
                          <div class="flex flex-col w-full">
                            <p class="text-14 font-semibold opacity-85">APIs</p>
                            <p class="text-14 text-graylight">Connects to the Gate.io
                              application ecosystem</p>
                          </div>
                        </a>
                        <a class=" hover:bg-bglightpink py-4 rounded transition-all px-4 flex items-center">
                          <img src="/assets/home/bitcoin-spot.png" alt="" class="w-6 mr-4 " />
                          <div class="flex flex-col w-full">
                            <p class="text-14 font-semibold opacity-85">Gate Wealth</p>
                            <p class="text-14 text-graylight">Gateway to Wealth</p>
                          </div>
                        </a>
                        <a class=" hover:bg-bglightpink py-4 rounded transition-all px-4 flex items-center">
                          <img src="/assets/home/bitcoin-spot.png" alt="" class="w-6 mr-4 " />
                          <div class="flex flex-col w-full">
                            <p class="text-14 font-semibold opacity-85">OTC Bank Transfer</p>
                            <p class="text-14 text-graylight">Official crypto on/off ramp
                              channel via Gate's bank account</p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </dropdown>
                </div>
              </li>
              <li class="group flex hidden">
                <div class="relative inline-block text-left" @click="openDropdown(momentsmenu.id)">
                  <span
                    class="cursor-pointer text-theme-color text-sm flex items-center gap-1 group-hover:text-pinkHover font-medium mt-1">
                    <img src="/assets/home/grow.gif" alt="" class="size-3.5" /> Moments
                    <i class="pi pi-sort-down-fill transition-all duration-500 ease-in-out group-hover:text-pinkHover group-hover:rotate-185 text-7 text-theme-color"
                      :id="'menu-button-' + momentsmenu.id" aria-expanded="true" aria-haspopup="true"></i>
                </span>

                  <dropdown :id="momentsmenu.id"
                    class="absolute top- left-0 z-10 mt-2 w-96 origin-top-left rounded bg-white shadow-lg ring-1 py-3 ring-black ring-opacity-5 focus:outline-none hidden"
                    role="menu" aria-orientation="vertical" :aria-labelledby="'menu-button-' + menu.id" tabindex="-1">
                    <div class="px-3" role="none">
                      <a v-for="(item, index) in momentsmenu.items" :key="index" :href="item.url"
                        class="group/img hover:bg-bglightpink py-4 rounded transition-all px-4 flex items-center">
                        <img :src="item.icon" alt="" class="w-6 mr-4" />
                        <div class="flex flex-col w-full">
                          <p class="text-14 font-semibold opacity-85 flex items-center justify-between">{{ item.text
                            }}<span><img src="/assets/referral/right-arrow.svg" alt="" width="16"
                                class="opacity-0 group-hover/img:opacity-100 ml-5"></span></p>
                          <p class="text-14 text-graylight">{{ item.description }}</p>
                        </div>
                      </a>
                    </div>
                  </dropdown>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="right-nav">
          <ul class="flex gap-4 items-center mb-0">
            <!-- asset -->
            <li class="group hidden lg:block" v-if="isLogined">
              <div class="relative inline-block text-left" @click="openDropdown('dropdown9')">
                <div 
                  class="text-theme-color text-sm flex items-center gap-1 group-hover:text-pinkHover font-medium cursor-pointer">Assets
                  <i class="pi pi-sort-down-fill transition-all duration-500 ease-in-out group-hover:text-pinkHover group-hover:rotate-185 text-7 text-theme-color"
                    id="menu-button" aria-expanded="true" aria-haspopup="true"></i>
                </div>

                <dropdown id="dropdown9"
                  class="hide-dropdown absolute top- right-50 z-10 mt-2 origin-top-left rounded bg-white shadow-lg ring-1 py-3 ring-black ring-opacity-5 focus:outline-none hidden -right-6 w-72"
                  role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                  <div class="flex px-3">
                    <div class="w-full">

                      <router-link to="/myfunds/spot"
                        class=" hover:bg-bglightpink py-2 rounded transition-all px-1 flex items-center w-full group/p">
                        <img src="/assets/header/express.png" alt="" width="" class="w-12 mr-4">
                        <div class="flex flex-col w-full">
                          <p class="text-14 font-semibold opacity-85 group-hover/p:text-pinkHover mb-0">Spot</p>
                          <p class="text-graylight text-12 mb-0">Deposit&Withdraw</p>
                        </div>
                      </router-link>
                      <router-link to="/transfer"
                        class=" hover:bg-bglightpink py-2 rounded transition-all px-1 flex items-center w-full group/p">
                        <img src="/assets/header/express.png" alt="" width="" class="w-12 mr-4">
                        <div class="flex flex-col w-full">
                          <p class="text-14 font-semibold opacity-85 group-hover/p:text-pinkHover mb-0">Transfer</p>

                        </div>

                      </router-link>

                    </div>
                  </div>
                </dropdown>
              </div>
            </li>
            <!-- login -->
            <li class="hidden sm:block" v-if="!isLogined">
              <router-link to="/login" class="text-theme-color text-sm">Log In</router-link>
            </li>
            <!-- signup -->
            <li class="hidden sm:block" v-if="!isLogined">
              <router-link to="/signup" class="text-white text-sm bg-pinkHover py-1.5 px-3 rounded">Sign
                Up</router-link>
            </li>
            <!-- profile -->
            <li class="hidden md:block" v-if="isLogined">
              <router-link to="/profile">
                <i class="pi pi-user text-theme-color"></i>
              </router-link>
            </li>

            <li>
              <div class="d-lg-none z-999 relative ml-4">
                <i class="pi pi-bars  cursor-pointer absolute text-theme-color right-0 -top-2" @click="toggleSidebar"></i>
                <div :class="{ 'translate-x-0': isVisible, 'translate-x-full': !isVisible }"
                  class="sidebar-container fixed top-0 right-0 bottom-0 w-320 bg-white transition-transform duration-300 ease-in-out">
                  <div class="sidebar h-full">
                    <div class="main-sidebar h-full bg-white flex flex-col">
                      <div class="flex items-center justify-between mt-3 px-4">
                        <div class="main-logo">

                          <img src="/assets/gdc-logo.png" alt="" width="100">

                        </div>
                        <div
                          class="toggle flex items-center gap-2 text-sm transition font-semibold hover:bg-SideHover hover:text-pinkHover"
                          @click="mobilesidebarClose">
                          <i class="pi pi-times text-darktext text-16 font-bold"></i>
                        </div>
                      </div>

                      <div class="flex align-middle justify-between gap-2 px-4 mt-3" v-if="!isLogined">
                        <router-link to="/login"
                          class="text-white text-sm bg-pinkHover py-1.5 px-3 w-full text-center rounded sm:hidden">Log
                          In</router-link>
                        <router-link to="/signup"
                          class="text-white text-sm bg-pinkHover py-1.5 px-3 w-full text-center rounded sm:hidden">Sign
                          Up</router-link>
                      </div>
                      <div class="w-full flex px-4 mt-5 gap-2" role="group">
                        <button type="button"
                          :class="{ 'bg-pinkHover text-white': activeTab === 'exchange', 'bg-SideHover': activeTab !== 'exchange' }"
                          @click="activeTab = 'exchange'"
                          class="px-4 py-2  text-white text-14 font-semibold w-full rounded focus:z-10 focus:text-whitedark:bg-gray-850 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">
                          Exchange
                        </button>
                        <button type="button"
                          :class="{ 'bg-pinkHover text-theme-color': activeTab === 'web', 'bg-SideHover': activeTab !== 'web' }"
                          @click="activeTab = 'web'" disabled
                          class="px-4 py-2  text-pinkHover text-14 font-semibold w-full rounded focus:z-10 focus:text-theme-color dark:bg-gray-850 dark:border-gray-700 dark:text-theme-color dark:hover:text-theme-color dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-theme-color">
                          Web3
                        </button>
                      </div>
                      <div class="rounded mt-2">

                        <details class="group rounded">
                          <summary class="flex items-center justify-between cursor-pointer w-full hover:bg-bglightpink">
                            <div class="flex align-middle px-4 py-2 align-items-end">
                              <img src="/assets/header/ventures.png" alt="" class="w-8 mr-2">
                              <h5 class="text-medium font-semibold text-14">
                                Buy Crypto
                              </h5>
                            </div>

                            <span class="relative flex-shrink-0 ml-1.5 w-5 h-5">
                              <!-- Downward Arrow Icon -->
                              <svg xmlns="http://www.w3.org/2000/svg"
                                class="absolute inset-0 w-5 h-5 opacity-100 group-open:opacity-0" fill="#7e8494"
                                viewBox="0 0 24 24">
                                <path d="M12 14l-6-6 1.41-1.41L12 11.17l4.59-4.58L18 8z" />
                              </svg>
                              <!-- Upward Arrow Icon -->
                              <svg xmlns="http://www.w3.org/2000/svg"
                                class="absolute inset-0 w-5 h-5 opacity-0 group-open:opacity-100" fill="#7e8494"
                                viewBox="0 0 24 24">
                                <path d="M12 10l6 6-1.41 1.41L12 12.83l-4.59 4.58L6 16z" />
                              </svg>
                            </span>
                          </summary>

                          <ul class="bg-main-gray">
                            <li class="cursor-pointer">
                              <a v-for="(item, index) in menu.items" :key="index" :href="item.url"
                                class=" hover:bg-bglightpink py-3.5 rounded transition-all px-4 flex items-center w-full">
                                <div class="flex flex-col w-full">
                                  <p class="text-14 font-semibold opacity-85">{{
                                    item.text }}</p>
                                  <p class="text-graylight text-12">{{
                                    item.description }}</p>
                                </div>
                              </a>
                            </li>

                          </ul>
                        </details>
                      </div>

                      <div class="rounded mt-2">

                        <details class="group rounded">
                          <summary class="flex items-center justify-between cursor-pointer w-full hover:bg-bglightpink">
                            <div class="flex align-middle px-4 py-2 align-items-end">
                              <img src="/assets/header/express.png" alt="" class="w-8 mr-2">
                              <h5 class="text-medium font-semibold text-14">
                                Trade
                              </h5>
                            </div>
                            <span class="relative flex-shrink-0 ml-1.5 w-5 h-5">
                              <!-- Downward Arrow Icon -->
                              <svg xmlns="http://www.w3.org/2000/svg"
                                class="absolute inset-0 w-5 h-5 opacity-100 group-open:opacity-0" fill="#7e8494"
                                viewBox="0 0 24 24">
                                <path d="M12 14l-6-6 1.41-1.41L12 11.17l4.59-4.58L18 8z" />
                              </svg>
                              <!-- Upward Arrow Icon -->
                              <svg xmlns="http://www.w3.org/2000/svg"
                                class="absolute inset-0 w-5 h-5 opacity-0 group-open:opacity-100" fill="#7e8494"
                                viewBox="0 0 24 24">
                                <path d="M12 10l6 6-1.41 1.41L12 12.83l-4.59 4.58L6 16z" />
                              </svg>
                            </span>
                          </summary>
                          <ul class="bg-main-gray">
                            <li class="cursor-pointer">
                              <router-link to="/spot/BTCUSDT"
                                class=" hover:bg-bglightpink py-3.5 rounded transition-all px-4 flex items-center w-full group/p">
                                <div class="flex flex-col w-full">
                                  <p class="text-14 font-semibold opacity-85 group-hover/p:text-pinkHover">Spot</p>
                                  <p class="text-graylight text-12">Top future traders</p>
                                </div>
                              </router-link>
                            </li>
                          </ul>
                        </details>
                      </div>
                      <div class="rounded mt-2">

                        <details class="group rounded">
                          <summary class="flex items-center justify-between cursor-pointer w-full hover:bg-bglightpink">
                            <div class="flex align-middle px-4 py-2 align-items-end">
                              <img src="/assets/header/nft copy.png" alt="" class="w-8 mr-2">
                              <h5 class="text-medium font-semibold text-14 ">
                                Assets
                              </h5>
                            </div>
                            <span class="relative flex-shrink-0 ml-1.5 w-5 h-5">
                              <!-- Downward Arrow Icon -->
                              <svg xmlns="http://www.w3.org/2000/svg"
                                class="absolute inset-0 w-5 h-5 opacity-100 group-open:opacity-0" fill="#7e8494"
                                viewBox="0 0 24 24">
                                <path d="M12 14l-6-6 1.41-1.41L12 11.17l4.59-4.58L18 8z" />
                              </svg>
                              <!-- Upward Arrow Icon -->
                              <svg xmlns="http://www.w3.org/2000/svg"
                                class="absolute inset-0 w-5 h-5 opacity-0 group-open:opacity-100" fill="#7e8494"
                                viewBox="0 0 24 24">
                                <path d="M12 10l6 6-1.41 1.41L12 12.83l-4.59 4.58L6 16z" />
                              </svg>
                            </span>
                          </summary>
                          <ul class="bg-main-gray">
                            <!-- <li class="cursor-pointer">
                              <a
                                class=" hover:bg-bglightpink py-3.5 rounded transition-all px-4 flex items-center w-full">
                                <div class="flex flex-col w-full">
                                  <p class="text-14 font-semibold opacity-85">Funds Management</p>
                                </div>
                              </a>
                            </li> -->
                            <li class="cursor-pointer">
                              <router-link to="/myfunds/spot"
                                class=" hover:bg-bglightpink py-3.5 rounded transition-all px-4 flex items-center w-full group/p">
                                <div class="flex flex-col w-full">
                                  <p class="text-14 font-semibold opacity-85 group-hover/p:text-pinkHover">Spot</p>
                                  <p class="text-graylight text-12">Deposit&Withdraw</p>
                                </div>
                              </router-link>
                            </li>
                            <li class="cursor-pointer">
                              <router-link to="/transfer"
                                class="hover:bg-bglightpink py-3.5 rounded transition-all px-4 flex items-center w-full group/p">
                                <div class="flex flex-col w-full">
                                  <p class="text-14 font-semibold opacity-85 group-hover/p:text-pinkHover">Transfer</p>
                                </div>
                              </router-link>
                            </li>
                            <!-- <li class="cursor-pointer">
                              <a
                                class=" hover:bg-bglightpink py-3.5 rounded transition-all px-4 flex items-center w-full group/p">
                                <div class="flex flex-col w-full">
                                  <p class="text-14 font-semibold opacity-85 group-hover/p:text-pinkHover">Isolated
                                    Margin</p>
                                </div>
                              </a>
                            </li> -->
                            <!-- <li class="cursor-pointer">
                              <a
                                class=" hover:bg-bglightpink py-3.5 rounded transition-all px-4 flex items-center w-full group/p">
                                <div class="flex flex-col w-full">
                                  <p class="text-14 font-semibold opacity-85 group-hover/p:text-pinkHover">Futures</p>
                                </div>
                              </a>
                            </li> -->
                            <!-- <li class="cursor-pointer">
                              <a
                                class=" hover:bg-bglightpink py-3.5 rounded transition-all px-4 flex items-center w-full group/p">
                                <div class="flex flex-col w-full">
                                  <p class="text-14 font-semibold opacity-85 group-hover/p:text-pinkHover">Copy Trading
                                  </p>
                                </div>
                              </a>
                            </li> -->
                          </ul>
                        </details>
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>

  </div>
</template>

<script>
export default {
  name: 'HeaderView',
  components: {
  },
  data() {
    return {
      isVisible: false,
      menu: {
        "id": "dropdown1",
        "text": "Buy Crypto",
        "items": [
          {
            "text": "P2P Trading",
            "icon": "/assets/header/p2p-trading.png",
            "description": "Buy and sell crypto with multiple options",
            "url": "/p2p"
          },
        ],
      },
      copymenu: {
        "id": "dropdown7",
        "text": "Copy",
        "items": [

          {
            "text": "Futures Copy Trading",
            "icon": "/assets/home/bitcoin-spot.png",
            "description": "Grow your wealth by following top futures traders",
            "url": "#"
          },
          {
            "text": "Spot Copy Trading",
            "icon": "/assets/home/bitcoin-spot.png",
            "description": "Grasp market trends by following top spot traders",
            "url": "#"
          },
          {
            "text": "Bot Copy Trading",
            "icon": "/assets/home/bitcoin-spot.png",
            "description": "Grasp market trends by following top spot traders",
            "url": "#"
          }
        ],
      },
      trademenu: {
        "id": "dropdown10",
        "text": "Trade",
        "items": [

          {
            "text": "Spot",
            "icon": "/assets/header/express.png",
            "description": "Top futures traders",
            "url": "/spot/BTCUSDT"
          }
        ],
      },
      momentsmenu: {
        "id": "dropdown2",
        "text": "Moments",
        "items": [
          {
            "text": "Gate Post",
            "icon": "/assets/home/bitcoin-spot.png",
            "description": "Discover value in crypto",
            "url": "#"
          },
          {
            "text": "Live Stream",
            "icon": "/assets/home/bitcoin-spot.png",
            "description": "Live crypto market analysis",
            "url": "#"
          },
          {
            "text": "Group",
            "icon": "/assets/home/bitcoin-spot.png",
            "description": "Chat with crypto traders",
            "url": "#"
          },
          {
            "text": "Future Events",
            "icon": "/assets/home/bitcoin-spot.png",
            "description": "Calendar of Cryptoassets & Economic Data",
            "url": "#"
          },
          {
            "text": "News",
            "icon": "/assets/home/bitcoin-spot.png",
            "description": "What is happening in crypto",
            "url": "#"
          },
          {
            "text": "Gate Blog",
            "icon": "/assets/home/bitcoin-spot.png",
            "description": "Crypto industry articles",
            "url": "#"
          },
          {
            "text": "Block Trading",
            "icon": "/assets/home/bitcoin-spot.png",
            "description": "Complete large transactions quickly",
            "url": "#"
          }
        ]
      },
      menuItems: [
        {

          "id": "dropdown8",
          "text": "Menu Item",
          "items": [
            {
              "text": "Exchange",
              "subItems": [
                {
                  "text": "Exchange",
                  "description": "Trade over 1,700 cryptocurrencies",
                  "icon": "/assets/header/express.png"
                },
                {
                  "text": "Startup",
                  "description": "Token launch and airdrop platform",
                  "icon": "/assets/header/startup.png"
                },
                {
                  "text": "Ventures",
                  "description": "Venture capital for Web3.0",
                  "icon": "/assets/header/ventures.png"
                },
                {
                  "text": "NFT",
                  "description": "Find and create your own digital assets",
                  "icon": "/assets/header/nft.png"
                },
                {
                  "text": "Leaderboard",
                  "description": "Top crypto traders",
                  "icon": "/assets/header/leaderboard.png"
                },
                {
                  "text": "Help & Center",
                  "description": "",
                  "icon": "/assets/header/help-center.png"
                },
                {
                  "text": "Announcements",
                  "description": "Latest platform updates regarding listings, activities, maintenances and more.",
                  "icon": "/assets/header/announcement.png"
                }
              ]
            },
            {
              "text": "Promotions",
              "subItems": [
                {
                  "text": "ETH 2.0 Upgrade",
                  "description": "ETH’s The Merge has finished",
                  "icon": "/assets/header/eth-upgrade.png"
                },
                {
                  "text": "Affiliate Program",
                  "description": "Earn commissions by sharing Gate.io with your audience",
                  "icon": "/assets/header/affi-program.png"
                },
                {
                  "text": "Influencer Program",
                  "description": "Affiliate Starter Edition",
                  "icon": "/assets/header/influencer-program.png"
                },
                {
                  "text": "Referral Program",
                  "description": "Invite your friends for fee disccount and more",
                  "icon": "/assets/header/referral-program.png"
                },
                {
                  "text": "Rewards Hub",
                  "description": "Complete tasks and win up to $5,000 prizes",
                  "icon": "/assets/header/rewards-hub.png"
                },
                {
                  "text": "Institutional and VIP Services",
                  "description": "Services for quant, market makers and brokers",
                  "icon": "/assets/header/vip-services.png"
                },
                {
                  "text": "gate Shop",
                  "description": "Exquisite Gate Peripheral Merchandise",
                  "icon": "/assets/header/gate-shop.png"
                }
              ]
            },
            {
              "text": "Learn & Explore",
              "subItems": [
                {
                  "text": "Learn",
                  "description": "Earn crypto while learning",
                  "icon": "/assets/header/learn.png"
                },
                {
                  "text": "Gate Blog",
                  "description": "Gain insight on crypto",
                  "icon": "/assets/header/gate-blog.png"
                },
                {
                  "text": "Gate Post",
                  "description": "Discover value in crypto",
                  "icon": "/assets/header/gate-post.png"
                },
                // {
                //   "text": "Live Stream",
                //   "description": "Live crypto market analysis",
                //   "icon": "/assets/home/header/gate-shop.png"
                // },
                // {
                //   "text": "Group",
                //   "description": "Chat with crypto traders",
                //   "icon": "/assets/home/header/gate-shop.png"
                // },
                // {
                //   "text": "News",
                //   "description": "What is happening in crypto",
                //   "icon": "/assets/home/header/gate-shop.png"
                // }
              ]
            }

          ]
        }
      ],
      financemenu: [
        {
          "id": "dropdown5",
          "items": [
            {
              "financecategory": "Earn",
              "options": [
                {
                  "text": "Simple Earn",
                  "description": "Earn interest on your idle funds. Principal and return are guaranteed with always over 100% collateral from your counterparties.",
                  "icon": "/assets/home/bitcoin-spot.png"
                },
                {
                  "text": "HODL & Earn",
                  "description": "A one stop investment center with a variety of products.",
                  "icon": "/assets/home/bitcoin-spot.png"
                },
                {
                  "text": "Structured Products",
                  "description": "Pre-packed products that include assets linked to interest and derivatives",
                  "icon": "/assets/home/bitcoin-spot.png"
                },
                {
                  "text": "Auto-Invest",
                  "description": "One-click investment, flexible portfolio, redeemable and changeable anytime",
                  "icon": "/assets/home/bitcoin-spot.png"
                },
                {
                  "text": "Dual Investment",
                  "description": "Short-term investment with exposure to two cryptos",
                  "icon": "/assets/home/bitcoin-spot.png"
                }
              ]
            },
            {
              "financecategory": "Loan",
              "options": [
                {
                  "text": "Gate Wealth",
                  "description": "Gateway to Wealth",
                  "icon": "/assets/home/bitcoin-spot.png"
                },
                {
                  "text": "Crypto Loan",
                  "description": "Crypto loan is to pledge one crypto to borrow another",
                  "icon": "/assets/home/bitcoin-spot.png"
                }
              ]
            },
            {
              "financecategory": "More",
              "options": [
                {
                  "text": "ETH2.0 Staking",
                  "description": "Stake ETH and get 100% on-chain return. Unstake anytime without delay.",
                  "icon": "/assets/home/bitcoin-spot.png"
                },
                {
                  "text": "Liquidity Mining",
                  "description": "Earn income from fees by providing Dual-Assets liquidity to the market",
                  "icon": "/assets/home/bitcoin-spot.png"
                },
                {
                  "text": "Cloud Mining",
                  "description": "Mining cryptocurrency without buying hardware",
                  "icon": "/assets/home/bitcoin-spot.png"
                },
                {
                  "text": "Slot Auctions",
                  "description": "Participate in parachain auctions & receive rewards.",
                  "icon": "/assets/home/bitcoin-spot.png"
                }
              ]
            }
          ]
        }
      ],
      botmenuItems: [
        {
          "id": "dropdown6",
          "text": "Bots",
          "items": [
            {
              "category": "Basic",
              "options": [
                {
                  "title": "Spot Grid",
                  "description": "Buy the dips during the downturn and sell all at once to gain profits when the price rebounds",
                  "imageSrc": "/assets/home/bitcoin-spot.png"
                },
                {
                  "title": "Futures Grid",
                  "description": "Take advantage of price fluctuations through multiple trade sides",
                  "imageSrc": "/assets/home/bitcoin-spot.png"
                },
                {
                  "title": "Spot Martingale",
                  "description": "Buy the dip in batches and sell once to gain more with lower risks",
                  "imageSrc": "/assets/home/bitcoin-spot.png"
                },
                {
                  "title": "Smart Rebalance",
                  "description": "Dynamically rebalance your Portfolio. An ideal option for Coin-M trading.",
                  "imageSrc": "/assets/home/bitcoin-spot.png"
                }
              ]
            },
            {
              "category": "Advanced",
              "options": [
                {
                  "title": "Combine Indicator",
                  "description": "Multiple Indicators bring flexible bots",
                  "imageSrc": "/assets/home/bitcoin-spot.png"
                },
                {
                  "title": "Spot-Futures Arbitrage",
                  "description": "Riskless arbitrage through spot & futures hedging",
                  "imageSrc": "/assets/home/bitcoin-spot.png"
                },
                {
                  "title": "Infinite Grid",
                  "description": "No upper limit. A grid bot that always keep running.",
                  "imageSrc": "/assets/home/bitcoin-spot.png"
                },
                {
                  "title": "Margin Grid",
                  "description": "Advanced version of grid trading, using leverage to amplify profits",
                  "imageSrc": "/assets/home/bitcoin-spot.png"
                }
              ]
            },
            {
              "category": "Explore",
              "options": [
                {
                  "title": "More About Bots",
                  "description": "Everything you need to know about bots",
                  "imageSrc": "/assets/home/bitcoin-spot.png"
                },
                {
                  "title": "Trade With Bots",
                  "description": "Bots help you to breeze through trading",
                  "imageSrc": "/assets/home/bitcoin-spot.png"
                },
                {
                  "title": "Bot Pool",
                  "description": "One-click copy, Auto running",
                  "imageSrc": "/assets/home/bitcoin-spot.png"
                }
              ]
            }
          ]
        }
      ],
      groupmenu: {

        "dropdown3": {
          "text": "Trade",
          "items": [
            {
              "text": "Spot",
              "icon": "/assets/home/bitcoin-spot.png",
              "description": "Trade with advanced order types",
              "url": "#"
            },
            {
              "text": "Convert",
              "icon": "/assets/home/bitcoin-spot.png",
              "description": "Trade one crypto for another simply",
              "url": "#"
            },
            {
              "text": "Margin",
              "icon": "/assets/home/bitcoin-spot.png",
              "description": "Amplify your trading power with up to 10x leverage",
              "url": "#"
            },
            {
              "text": "Leveraged Tokens",
              "icon": "/assets/home/bitcoin-spot.png",
              "description": "Get exposure to leveraged positions simply",
              "url": "#"
            },
            {
              "text": "Startup",
              "icon": "/assets/home/bitcoin-spot.png",
              "description": "Token launch and airdrop platform",
              "url": "#"
            },
            {
              "text": "Bots",
              "icon": "/assets/home/bitcoin-spot.png",
              "description": "Diverse bots support you earn profits automatically",
              "url": "#"
            },
            {
              "text": "Block Trading",
              "icon": "/assets/home/bitcoin-spot.png",
              "description": "Complete large transactions quickly",
              "url": "#"
            }
          ]
        },
        "dropdown4": {
          "text": "Derivatives",
          "items": [
            {
              "text": "Perpetual Futures",
              "icon": "/assets/home/bitcoin-spot.png",
              "description": "Perpetual contracts without expiration dates",
              "url": "#"
            },
            {
              "text": "Delivery Futures",
              "icon": "/assets/home/bitcoin-spot.png",
              "description": "Weekly or quarterly contracts with expiration dates",
              "url": "#"
            },
            {
              "text": "Option",
              "icon": "/assets/home/bitcoin-spot.png",
              "description": "Trade European-style Vanilla Options",
              "url": "#"
            },
            {
              "text": "Copy Trading",
              "icon": "/assets/home/bitcoin-spot.png",
              "description": "Automate your trading by copying the top traders",
              "url": "#"
            },
            {
              "text": "Unified Account",
              "icon": "/assets/home/bitcoin-spot.png",
              "description": "Share margin, allow to open new positions with unrealized profits and thus enhance capital efficiency",
              "url": "#"
            }
          ]
        },
        // Add more dropdowns here as needed
      },
      dropdownClass: "hide-dropdown absolute top- right-50 z-10 mt-2 w-100 origin-top-left rounded bg-white shadow-lg ring-1 py-5  ring-black ring-opacity-5 focus:outline-none hidden min-w-1100 -left-32",
      dropdownOrientation: "vertical",
      dropdownTabindex: "-1",
      activeTab: 'exchange',
      dropdownOpen: false,
      BuyCrypto: false,
      isLogined: true,
    }
  },

  mounted() {
    this.isLogined = localStorage.getItem("token") || false;
  },
  watch: {
    // Watch for changes to login status and update accordingly
    '$route'() {
      this.isLogined = localStorage.getItem("token") || false;
    },
  },
  methods: {
    toggleSidebar() {
      this.isVisible = !this.isVisible;
    },
    mobilesidebarClose() {
      this.isVisible = false;
    },
    openDropdown(dropdownId) {
      const dropdowns = document.querySelectorAll('.hide-dropdown');
      dropdowns.forEach(dropdown => {
        if (dropdown.id !== dropdownId) {
          dropdown.classList.add("hidden");
        }
      });
      document.getElementById(dropdownId).classList.toggle("hidden");
    },
    hideDropdowns() {

      var hideDropdowns = document.getElementsByClassName('hide-dropdown');
      for (var i = 0; i < hideDropdowns.length; i++) {
        hideDropdowns[i].classList.add("hidden");
      }
    }
  },


}

</script>

<style>
.header_container {
  box-shadow:0px 0px 5px rgba(0, 0, 0, 0.5);
}
</style>
