import axios from "axios";
import Swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
export default class ApiClass {
  // static nodeUrl = "https://nbitx.ethersofts.com/";
  // static SocketnodeUrl = "ws://192.168.1.17:3321/";

  static SocketnodeUrl = "wss://stream.binance.com:9443/ws";
  static nodeUrl = "https://demoexchangenode.tecplayers.com/";

  static authUrl = "https://demoexchangenode.tecplayers.com/auth/";
  static baseUrl = "https://demoexchangenode.tecplayers.com/api/";
  static adminUrl = "https://demoexchangenode.tecplayers.com/";

  //  function for auth routes
  static async getRequest(
    apiUrl,
    isToken = true,
    headers = null,
    params = null
  ) {
    try {
      const result = await axios.get(
        this.nodeUrl + apiUrl,
        this.config(isToken, headers, params)
      );
      return result.data;
    } catch (error) {
      console.error("Unauthorized:", error.message);
    }
  }

  static async postRequest(
    apiUrl,
    isToken = true,
    formData = null,
    headers = null,
    params = null
  ) {
    try {
      const response = await axios.post(
        this.authUrl + apiUrl,
        formData,
        this.config(isToken, headers, params)
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          this.unauthenticateRedirect();
        } else {
          console.error("API Error:", error.response.data);
          throw error.response.data;
        }
      } else {
        console.error("Network or other error:", error.message);
        throw error.message;
      }
    }
  }

  // function for api routes
  static async getNodeRequest(
    apiUrl,
    isToken = true,
    headers = null,
    params = null
  ) {
    try {
      const result = await axios.get(
        this.baseUrl + apiUrl,
        this.config(isToken, headers, params)
      );
      return result.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          this.unauthenticateRedirect();
        } else {
          console.error("API Error:", error.response.data);
          throw error.response.data;
        }
      } else {
        console.error("Network or other error:", error.message);
        throw error.message;
      }
    }
  }

  static async postNodeRequest(
    apiUrl,
    isToken = true,
    formData = null,
    headers = null,
    params = null
  ) {
    try {
      const response = await axios.post(
        this.baseUrl + apiUrl,
        formData,
        this.config(isToken, headers, params)
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 429) {
          this.unauthenticateRedirect();
        } else {
          console.error("API Error:", error.response.data);
          throw error.response.data;
        }
      } else {
        console.error("Network or other error:", error.message);
        throw error.message;
      }
    }
  }

  static async updateNodeRequest(
    apiUrl,
    isToken = true,
    formData = null,
    headers = null,
    params = null
  ) {
    try {
      const response = await axios.put(
        this.baseUrl + apiUrl,
        formData,
        this.config(isToken, headers, params)
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          this.unauthenticateRedirect();
        } else {
          console.error("API Error:", error.response.data);
          throw error.response.data;
        }
      } else {
        console.error("Network or other error:", error.message);
        throw error.message;
      }
    }
  }

  static async deleteNodeRequest(
    apiUrl,
    isToken = true,
    headers = null,
    params = null
  ) {
    try {
      const result = await axios.delete(
        this.baseUrl + apiUrl,
        this.config(isToken, headers, params)
      );
      return result.data;
    } catch (error) {
      if (error.response.status === 401) {
        this.failed(error.response.data);
      } else {
        console.error("Error:", error.response.data);
        throw error.response.data;
      }
    }
  }
  // admin route
  static async getAdminRequest(
    apiUrl,
    isToken = true,
    headers = null,
    params = null
  ) {
    try {
      const result = await axios.get(
        this.adminUrl + apiUrl,
        this.config(isToken, headers, params)
      );
      return result.data;
    } catch (error) {
      console.error("Unauthorized:", error.message);
    }
  }


  static config(isToken = true, headers = null, parameters = null) {
    var defaultHeaders = {
      Accept: "application/json",
    };
    var merge = {};
    if (isToken) {
      var token = { Authorization: "Bearer " + localStorage.getItem("token") };

      merge = Object.assign(defaultHeaders, token);
    }
    merge = Object.assign(defaultHeaders, headers);
    return {
      headers: merge,
      params: parameters,
    };
  }

  static unauthenticateRedirect() {   
    Swal.fire({
      title: 'Unauthorized Access or try to login again',
      position: "top-end",
      icon: "error",
      toast: true,
      timer: 3000,
      showConfirmButton: false,
    });
    localStorage.clear();
    location.replace("/login");
  }
}
