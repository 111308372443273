import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const UserLoginView = () => import("@/views/auth/UserLoginView.vue");
const SignUp = () => import("@/views/auth/SignUp.vue");
const ForgotPassword = () => import("@/views/auth/ForgotPassword.vue");

const routes = [
  {
    path: "/login",
    name: "UserLoginView",
    component: UserLoginView,
    meta: { guestOnly: true },
  },
  {
    path: "/signup",
    name: "SignUp",
    component: SignUp,
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    // meta: { authOnly: true },
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: { authOnly: false },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/spot/:slug",
    name: "TradingSpot",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/TradingSpot.vue"),
  },
  {
    path: "/profile",
    name: "ProfileView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ProfileView.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/security-settings",
    name: "SecurityVerification",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/SecurityVerification.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/my-referrals",
    name: "MyReferrals",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MyReferrals.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/kyc",
    name: "KycView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/KycView.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/login-history",
    name: "LoginHistory",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/LoginHistory.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/myfunds/spot",
    name: "MyFunds",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MyFundsSpot.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/deposit/:fund",
    name: "deposit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BtcDeposit.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/spot/withdraw",
    name: "withdraw",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BtcWithdraw.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/listing",
    name: "ListingView",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ListingView.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/listing-application",
    name: "ListingApplication",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ListingApplication.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/listing-incubation",
    name: "IncubationApplication",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/IncubationApplication.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/withdrawal/:type/:fund",
    name: "withdraw",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BtcWithdrawSpot.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/p2p",
    name: "p2p",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/P2P/views/P2PView.vue"),
    meta: { authOnly: false },
  },
  {
    path: "/portfolio",
    name: "PortfolioView",
    component: () =>
      import(
        /* webpackChunkName: "p2p" */ "../views/P2P/views/PortfolioView.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/orders",
    name: "orders",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/P2P/views/OrderView.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/advertiser-details",
    name: "advertiser-details",
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/P2P/views/AdvertiserDetailsView.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/postnormal",
    name: "Post Normal",
    component: () =>
      import(
        /* webpackChunkName: "Post Normal" */ "../views/P2P/views/PostNormal.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/myads",
    name: "My Ads",
    component: () =>
      import(/* webpackChunkName: "My Ads" */ "../views/P2P/views/MyAds.vue"),
    meta: { authOnly: true },
  },
  {
    path: "/payment",
    name: "payment",
    component: () =>
      import(
        /* webpackChunkName: "payment" */ "../views/P2P/views/PaymentView.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/Add",
    name: "PAYTM",
    component: () =>
      import(
        /* webpackChunkName: "PAYTM" */ "../views/P2P/views/AddPayment.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/buy_detail",
    name: "Buy Detail",
    component: () =>
      import(
        /* webpackChunkName: "orderdetail" */ "../views/P2P/views/BuyDetailView.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/sell_detail",
    name: "Sell Detail",
    component: () =>
      import(
        /* webpackChunkName: "Sell Detail" */ "../views/P2P/views/SellDetailView.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/orderstatus",
    name: "orderstatus",
    component: () =>
      import(
        /* webpackChunkName: "orderstatus" */ "../views/P2P/views/OrderStatusView.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/deposite",
    name: "deposite",
    component: () =>
      import(
        /* webpackChunkName: "deposite" */ "../views/P2P/views/DepositeView.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/Withdraw",
    name: "Withdraw",
    component: () =>
      import(
        /* webpackChunkName: "Withdraw" */ "../views/P2P/views/WithdrawView.vue"
      ),
    meta: { authOnly: true },
  },
  {
    path: "/transfer",
    name: "Transfer",
    component: () =>
      import(
        /* webpackChunkName: "Transfer" */ "../views/TransferView.vue"
      ),
    meta: { authOnly: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

function isLoggedIn() {
  let user_token = localStorage.getItem("token");
  return user_token;
}

router.beforeEach((to, from, next) => {
  const isAuthRequired = to.matched.some((record) => record.meta.authOnly);
  const isGuestOnly = to.matched.some((record) => record.meta.guestOnly);
  const loggedIn = isLoggedIn();

  if (isAuthRequired && !loggedIn) {
    return next({ path: "/login" });
  } else if (isGuestOnly && loggedIn) {
    return next({ path: "/" }); // Redirect logged-in users to home or another appropriate route
  } else {
    return next();
  }
});

export default router;
