<template>
	<div>
		<footer class="bg-darkColor">
			<div class="py-9 border-b-2 border-gray-800">
				<div class="px-3 lg:container mx-auto">
					<div class=" grid-cols-1 md:grid-cols-5 gap-4 hidden md:grid">
						<div class="about-area">
							<h4 class="text-2xl text-white pb-4">About</h4>
							<ul class="pl-0">
								<li class="group mt-2">
									<a href="/"
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Exchange
									</a>
								</li>
								<li class="group mt-2">
									<a href="/spot/BTCUSDT"
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Trade
									</a>
								</li>
								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Careers
									</span>
								</li>
								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Price
									</span>
								</li>

								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Terms
									</span>
								</li>
								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Privacy
									</span>
								</li>
							</ul>
						</div>
						<div class="product-area">
							<h4 class="text-2xl text-white pb-4">Products</h4>
							<ul class="pl-0">

								<li class="group mt-2">
									<a href="/p2p"
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Buy
										Crypto
									</a>
								</li>
								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Pay
									</span>
								</li>
								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Live
									</span>
								</li>
								<li class="group mt-2">
									<span class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">
										Gift Card
									</span>
								</li>
								<li class="group mt-2">
									<span class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">
										Lunchpool
									</span>
								</li>
								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">ETH
										Staking
									</span>
								</li>
								<li class="group mt-2">
									<span class="text-gray-400 flex items-center text-base group-hover:text-pinkHover gap-1 font-medium">
										NFT
									</span>
								</li>
							</ul>
						</div>
						<div class="services-area">
							<h4 class="text-2xl text-white pb-4">Services</h4>
							<ul class="pl-0">
								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Affiliate
									</span>
								</li>
								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Referral
									</span>
								</li>
								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Historical
										Market Data
									</span>
								</li>
								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Proof
										of Reserves
									</span>
								</li>
							</ul>
						</div>
						<div class="moments-area">
							<h4 class="text-2xl text-white pb-4">Moments</h4>
							<ul class="pl-0">
								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Listing
									</span>
								</li>
								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Learn
										& Earn
									</span>
								</li>
								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Browse
										Crypto Prices
									</span>
								</li>
							</ul>
						</div>
						<div class="finance-area">
							<h4 class="text-2xl text-white pb-4">Finance</h4>
							<ul class="pl-0">
								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">P2P
										Merchant Application
									</span>
								</li>
								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">P2Pro
										Merchant Application
									</span>
								</li>
								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Listing
										Application
									</span>
								</li>
								<li class="group mt-2">
									<span
										class="text-gray-400 text-base flex items-center gap-1 group-hover:text-pinkHover font-medium">Institutional
										& VIP Services
									</span>
								</li>
							</ul>
						</div>
					</div>
					<div class="grid-cols-1 grid md:hidden mb-5">
						<div class="today-market-table md:mt-10">
							<div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
								<div class=" rounded  sm:mt-0">
									<details class="p-2 group" v-for="(item, index) in jsonData.items" :key="index">
										<summary class="flex items-center justify-between cursor-pointer">
											<h4 class="text-16 text-white">{{ item.name }}</h4>
											<span class="relative flex-shrink-0 ml-1.5 w-7 h-7">
												<!-- Downward Arrow Icon -->
												<svg xmlns="http://www.w3.org/2000/svg"
													class="absolute inset-0 w-7 h-7 opacity-100 group-open:opacity-0" fill="#fff"
													viewBox="0 0 24 24">
													<path d="M12 14l-6-6 1.41-1.41L12 11.17l4.59-4.58L18 8z" />
												</svg>
												<!-- Upward Arrow Icon -->
												<svg xmlns="http://www.w3.org/2000/svg"
													class="absolute inset-0 w-7 h-7 opacity-0 group-open:opacity-100" fill="#fff"
													viewBox="0 0 24 24">
													<path d="M12 10l6 6-1.41 1.41L12 12.83l-4.59 4.58L6 16z" />
												</svg>
											</span>
										</summary>

										<ul class="pl-0">
											<li v-if="item.children" class="">
												<!-- Dropdown for sub-items -->
												<ul class="pl-0">
													<li v-for="(subItem, subIndex) in item.children" :key="subIndex"
														class="hover:text-pinkHover mt-2">
														<router-link :to="subItem.url"
															class="text-gray-400 text-base flex items-center gap-1 font-medium">
															{{ subItem.name }}
														</router-link>
													</li>
												</ul>
											</li>
										</ul>
									</details>

								</div>
							</div>
						</div>
					</div>

				</div>
				<div class="lg:container mx-auto px-3">
					<div class="md:flex sub-footer">
						<div class="lg:flex">
							<div class="flex-none lg:w-full mr-8 relative">
								<div class="flex lg:justify-center justify-between">
									<div class=" grid-cols-1 gap-6 hidden md:grid w-full mr-4">
										<div class=" rounded sm:mt-0">
											<details class="p-2 bg-secondarydark group  rounded absolute w-full">
												<summary class="flex items-center justify-between cursor-pointer rounded">
													<h5 class="text-medium font-semibold text-graylight line-clamp-1">
														English
													</h5>
													<span class="relative flex-shrink-0 ml-1.5 w-7 h-7">
														<!-- Downward Arrow Icon -->
														<svg xmlns="http://www.w3.org/2000/svg"
															class="absolute inset-0 w-7 h-7 opacity-100 group-open:opacity-0" fill="#7e8494"
															viewBox="0 0 24 24">
															<path d="M12 14l-6-6 1.41-1.41L12 11.17l4.59-4.58L18 8z" />
														</svg>
														<!-- Upward Arrow Icon -->
														<svg xmlns="http://www.w3.org/2000/svg"
															class="absolute inset-0 w-7 h-7 opacity-0 group-open:opacity-100" fill="#7e8494"
															viewBox="0 0 24 24">
															<path d="M12 10l6 6-1.41 1.41L12 12.83l-4.59 4.58L6 16z" />
														</svg>
													</span>
												</summary>

												<div class="grid grid-cols-3 gap-6 p-3 absolute bottom-11 left-0 bg-secondarydark w-full">
													<p class="text-white text-medium">English</p>

												</div>
											</details>
										</div>

									</div>
								</div>
							</div>
							<div class="w-100 flex gap-3 items-center justify-between p-2 lg:p-0 align-items-start">
								<img src="/assets/home/facebook.svg" alt="" width="24">
								<img src="/assets/home/youtube.svg" alt="" width="24">
								<img src="/assets/home/telegram.svg" alt="" width="24">
								<img src="/assets/home/envelope-solid.svg" alt="" width="24">
								<img src="/assets/home/square-instagram.svg" alt="" width="24">
							</div>
							<div class="today-market-table mt-5 md:mt-10 md:hidden">
								<div class="grid grid-cols-1 sm:grid-cols-2 gap-6 px-2">
									<div class=" rounded sm:mt-0">
										<details class="p-2 bg-secondarydark group  rounded relative">
											<summary class="flex items-center justify-between cursor-pointer rounded">
												<h5 class="text-medium font-semibold text-graylight line-clamp-1">
													English
												</h5>

												<span class="relative flex-shrink-0 ml-1.5 w-7 h-7">
													<!-- Downward Arrow Icon -->
													<svg xmlns="http://www.w3.org/2000/svg"
														class="absolute inset-0 w-7 h-7 opacity-100 group-open:opacity-0" fill="#7e8494"
														viewBox="0 0 24 24">
														<path d="M12 14l-6-6 1.41-1.41L12 11.17l4.59-4.58L18 8z" />
													</svg>
													<!-- Upward Arrow Icon -->
													<svg xmlns="http://www.w3.org/2000/svg"
														class="absolute inset-0 w-7 h-7 opacity-0 group-open:opacity-100" fill="#7e8494"
														viewBox="0 0 24 24">
														<path d="M12 10l6 6-1.41 1.41L12 12.83l-4.59 4.58L6 16z" />
													</svg>
												</span>
											</summary>

											<div class="grid grid-cols-3 gap-6 p-3 absolute left-0 bottom-12 bg-secondarydark w-full">
												<p class="text-white text-medium">English</p>

											</div>
										</details>
									</div>

									<div>
										<details class="p-2 bg-secondarydark group  rounded relative">
											<summary class="flex items-center justify-between cursor-pointer rounded">
												<h5 class="text-medium font-semibold text-graylight line-clamp-1">
													USD
												</h5>

												<span class="relative flex-shrink-0 ml-1.5 w-7 h-7">
													<!-- Downward Arrow Icon -->
													<svg xmlns="http://www.w3.org/2000/svg"
														class="absolute inset-0 w-7 h-7 opacity-100 group-open:opacity-0" fill="#7e8494"
														viewBox="0 0 24 24">
														<path d="M12 14l-6-6 1.41-1.41L12 11.17l4.59-4.58L18 8z" />
													</svg>
													<!-- Upward Arrow Icon -->
													<svg xmlns="http://www.w3.org/2000/svg"
														class="absolute inset-0 w-7 h-7 opacity-0 group-open:opacity-100" fill="#7e8494"
														viewBox="0 0 24 24">
														<path d="M12 10l6 6-1.41 1.41L12 12.83l-4.59 4.58L6 16z" />
													</svg>
												</span>
											</summary>

											<div class="grid grid-cols-3 gap-6 p-3 absolute left-0 bottom-12 bg-secondarydark w-full">
												<p class="text-white text-medium">USD</p>
												<p class="text-white text-medium">USD</p>
												<p class="text-white text-medium">USD</p>
												<p class="text-white text-medium">USD</p>
												<p class="text-white text-medium">USD</p>
												<p class="text-white text-medium">USD</p>
												<p class="text-white text-medium">USD</p>
												<p class="text-white text-medium">USD</p>
											</div>
										</details>
									</div>
								</div>

							</div>
						</div>
						<div class="">
							<h5 class="text-center lg:text-right text-gray-400 text-base">{{BRAND_NAME()}} © 2013-2024.</h5>
						</div>
					</div>
				</div>
			</div>

		</footer>
	</div>
</template>

<script>
export default {
	data() {
		return {
			jsonData: {
				name: 'Finance',
				items: [
					{
						name: 'About',
						url: '/',
						children: [
							{ name: 'Bots', url: '/' },
							{ name: 'Inscription', url: '/' },
							{ name: 'Finance', url: '/' },
							{ name: 'Inscription', url: '/' },
							{ name: 'Moments', url: '/' },
							{ name: 'Moments', url: '/' },

						]
					},
					{
						name: 'Price',
						url: '/',
						children: [
							{ name: 'Finance', url: '/' },
							{ name: 'Inscription', url: '/' },
						]
					},
					{
						name: 'Trade',
						url: '/',
						children: [
							{ name: 'Moments', url: '/' },
							{ name: 'Moments', url: '/' },
						]
					},
				]
			}
		};
	}
}
</script>

<style>
.sub-footer {
	display: flex;
	justify-content: space-between;
}

@media all and (max-width: 767px) {
	.sub-footer {
		flex-direction: column;
	}
}
</style>