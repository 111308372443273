import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  plugins: [createPersistedState()],
  state: {
    isMob: false,
    user: null,
    sluginfo: null,
    favourite: [],
    orderApiHit: false,
    editOrderInfo: null,
    market_currency: null,
    active_coin:null,
    routeSlug: null,
    fullInfo: null,
    maxBuy: 0,
    marketVal: null,
    chartClr: null,
    exchangeList: [],
    conversion_data: {},
    btc_price: 0,
    initialMaintenance: null,
    verifyOtpUserDetail: null,
    tradeData: null,
    activeButtonColor: null,
  },
  getters: {
    getToken() {
      return localStorage.getItem("token");
    },
    getEditInfo: (state) => {
      return state.editOrderInfo;
    },
    getMarketCurrency: (state) => {
      return state.market_currency;
    },
    getActiveCoin:(state) => {
      return state.active_coin;
    },
    isLoggedIn() {
      const tkn = localStorage.getItem("token");
      return tkn != null ? true : false;
    },
    favouriteCryptos: (state) => {
      return state.favourite;
    },
    allSluginfo: (state) => {
      return state.fullInfo;
    },
    availableBalance: (state) => {    
      return state.maxBuy;
    },
    marketV: (state) => {
      return state.marketVal;
    },
    chartColor: (state) => {
      return state.chartClr;
    },
    exList: (state) => {
      return state.exchangeList;
    },
    getConversionData: (state) => state.conversion_data,
    getManualTradeData: (state) => state.tradeData,
    getActiveColor: (state) => state.activeButtonColor,
  },
  mutations: {
    SET_VERIFYOTP_USER(state, payload) {
      state.verifyOtpUserDetail = payload;
    },
    changeMob(state, val) {
      state.isMob = val;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SLUG_INFO(state, sluginfo) {
      state.sluginfo = sluginfo;
    },
    RESET(state) {
      state.user = null;
    },
    setEditOrderInfo(state, payload) {
      state.editOrderInfo = payload;
    },
    SET_Market_Currency(state, payload) {
      state.market_currency = payload;
    },
    SET_ACTIVE_COIN(state, payload) {
      state.active_coin = payload;
    },
    SET_FAVOURITE(state, val) {
      state.favourite = val;
    },
    SLUG_FULL_INFO(state, val) {
      state.fullInfo = val;
    },
    AVAILABLE_AMOUNT(state, val) {    
      state.maxBuy = val;
    },
    SET_MARKET_FLUCT(state, val) {
      state.marketVal = val;
    },
    SET_CHART_THEME(state, val) {
      state.chartClr = val;
    },
    SET_EXCHANGE_LIST(state, val) {
      state.exchangeList = val;
    },
    SET_CONVERSION_DATA(state, val) {
      state.conversion_data = val;
    },
    SET_BTC_PRICE(state, val) {
      state.btc_price = val;
    },
    initialMaintenance(state, payload) {
      state.initialMaintenance = payload;
    },
    setManualTrade(state, payload) {
      state.tradeData = payload;
    },
    setButtonColor(state, payload) {
      state.activeButtonColor = payload;
    },
  },
  actions: {
    updateManualTrade({ commit }, payload) {
      commit("setManualTrade", payload);
    },
    updateButtonColor({ commit }, payload) {
      commit("setButtonColor", payload);
    },
  },
  modules: {},
});
