<template>
  <div class="home px-0">
    <div class="lg:container mx-auto py-8 px-4 lg:px-12 2xl:px-32 px-0 px-lg-5">
      <div class="">
        <div class="md:flex md:flex-row lg:gap-4 h-full w-full px-0 px-lg-5">
          <div class="basis-1/2 flex items-center md:pe-6 lg:pe-0 e px-0 px-lg-5">
            <div class="w-full">
              <p class=" font-semibold lg:font-bold text-28 lg:text-48">Gateway to Crypto</p>
              <p class="mt-3">Trade over 1,700 cryptocurrencies safely, quickly, and easily</p>
              <div class="flex">
                <img src="/assets/home/gift-home-solid.svg" alt="" width="20" class="">
                <p class="text-gray-400 my-2 lg:my-5 ml-2">Sign up and win up to $6666 in welcome rewards</p>
              </div>
              <div class="card lg:d-flex flex-row gap-0 mt-3 w-full border-none">
                <div class="flex items-center">
                  <input type="text" placeholder="Email or phone" v-model="email"
                    class="bg-gray-200 rounded-l-5 text-gray-500 pl-3 py-3 text-base w-full lg:w-200 h-full focus:outline-none">
                  <button @click="gotoSignup"
                    class="text-white font-bold bg-pinkHover px-7 py-3 lg:px-5 rounded lg:mr-5 whitespace-nowrap h-full">Sign
                    Up</button>
                </div>
                <div class="icon-links d-flex">
                  <div class="bg-gray-200  flex items-center justify-center py-3 rounded w-full h-14 lg:w-14 mr-3">
                    <img src="/assets/home/apple.png" alt="" class="w-10">
                  </div>
                  <div class="bg-gray-200  flex items-center justify-center py-3 rounded w-full h-14 lg:w-14 mr-3">
                    <img src="/assets/home/google.png" alt="" class="w-10">
                  </div>
                  <div class="bg-gray-200  flex items-center justify-center py-3 rounded w-full h-14 lg:w-14 mr-3">
                    <img src="/assets/home/meta.png" alt="" class="w-10">
                  </div>

                </div>
              </div>
              <div class="grid gap-x-6 grid-cols-2 md:grid-cols- xl:grid-cols-4 mt-4 xl:mt-20">
                <div>
                  <p class="text-16 md:text-20 lg:text-24 2xl:text-32 font-semibold whitespace-pre">$121.00 <sub
                      class="!text-xl !font-normal">B</sub></p>
                  <p class="text-xs text-gray-400 whitespace-pre">24h Trading Volume</p>
                </div>
                <div>
                  <p class="text-16 md:text-20 lg:text-24 2xl:text-32 font-semibold">1200 +</p>
                  <p class="text-xs text-gray-400 whitespace-pre">Cryptocurrencies Listed</p>
                </div>
                <div class="md:mt-0">
                  <p class="text-16 md:text-20 lg:text-24 2xl:text-32 font-semibold">12+ <sub
                      class="!text-xl !font-normal">M</sub></p>
                  <p class="text-xs text-gray-400 whitespace-pre">Registered Users</p>
                </div>
                <div class="md:mt-0">
                  <p class="text-16 md:text-20 lg:text-24 2xl:text-32 font-semibold">140.78 <sub
                      class="!text-xl !font-normal">%</sub></p>
                  <p class="text-xs text-gray-400">Simple Earn APR</p>
                </div>
              </div>

            </div>
          </div>
          <div class="basis-1/2  items-center hidden md:flex group border-none">
            <div class="card border-none">

              <carousel :items-to-show=".50" :breakpoints="breakpoints3" :transition="500" :wrap-around="true"
                vertical="true">
                <slide v-for="(option, index) in mainoptions" :key="index">
                  <div class="h-full">
                    <div class="flex items-center justify-center w-full max-w-400 ml-12">

                      <img :src="`/assets/home/${option.img}`" :alt="option.img" class="" />
                    </div>
                  </div>
                </slide>

                <template #addons="">
                  <div>
                    <Navigation></Navigation>
                  </div>
                </template>

              </carousel>
            </div>
          </div>
        </div>
      </div>
      <div class=" h-14  mx-0 mx-lg-5">
        <div
          class="overflow-hidden justify-between bg-gray-50 items-center py-2 px-6  mx-0 mx-lg-5 d-flex rounded border border-gray-100 mt-16 lg:mt-0 hidden md:flex md:mt-5">

          <div class="flex items-center w-1/2 mr-5 border-r-2 border-gray-200 mx-0 mx-lg-2">
            <img src="/assets/home/comment-dots-regular.svg" alt="" width="20" class="mr-4">
            <p :class="{ 'animate-roll-in': showAnimation }" class="mr-2 line-clamp-1 text-14">
              Unlock the Power Trading: Exchange Assets Instantly with Our Cutting-Edge Platform</p>
          </div>
          <div class="flex items-center w-1/2 ml-5 mx-0 mx-lg-3">
            <p :class="{ 'animate-roll-in': showAnimation }" class="flex-1 line-clamp-1 text-14">
              Trade with Confidence: Access Real-Time Data, Advanced Tools, and Competitive Rates</p>
            <img src="/assets/home/bars-solid.svg" alt="" width="16" class="ml-4">
          </div>
        </div>
      </div>

      <div class="today-market-table py-6 mt-3 md:mt-10 px-0 px-lg-5">
        <div class="flex sm:items-center sm:justify-between flex-col sm:flex-row px-0 px-lg-5">
          <p class="text-24 lg:text-40 font-semibold mb-3">Crypto Market Today</p>
        </div>
        <div class="px-0 px-lg-5">
          <CryptoMarketVIew />
        </div>
      </div>

      <div class="py-5 hidden xl:block px-0 px-lg-5 mt-4">
        <div class="flex items-center justify-between mb-3 px-0 px-lg-5">
          <p class="text-24 lg:text-40  font-semibold">Activities</p>
        </div>
        <div class="mt-8 px-0 px-lg-5">
          <carousel :items-to-show="4" :breakpoints="breakpoints1" :transition="500" :autoplay="1500"
            :wrap-around="true">
            <slide v-for="(option, index) in activityList" :key="index">
              <div class="main-img position-relative group">
                <div class="position-relative">
                  <img :src="`${option.image}`" :alt="option.image" class="rounded-lg w-72" />
                </div>
                <div
                  class="industry__content position-absolute flex justify-start items-start w-72 h-100 overflow-hidden shadow-md p-3 mb-4 rounded-b-lg transition-all opacity-0 group-hover:opacity-100">
                  <p class="fs-5 fw-normal lh-base line-clamp-2 font-semibold">{{ option.link }}</p>
                </div>
              </div>
            </slide>
            <template #addons="{ slidesCount }">
              <Navigation v-if="slidesCount > 1" />
            </template>
          </carousel>
        </div>
      </div>

      <div class="py-5 px-0 px-lg-5 mt-3">
        <p class="text-24 lg:text-40 font-semibold mb-3 px-0 px-lg-5">How to Get Started</p>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 mt-8 px-0 px-lg-5">
          <transition-group name="step" appear @before-enter="beforeEnter" @enter="enter">
            <div v-for="(step, index) in steps" :key="index" :data-index="index"
              class="md:flex md:items-center lg:inline-block p-6 bg-white border border-gray-200 rounded-lg hover:shadow-custom hover:bg-main-gray transition-all dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 hover:border-main-gray step-box">
              <div class="flex md:flex-1 lg:inline-block">
                <img :src="step.image" alt="" class="mb-3 w-52c md:w-72c">
                <div class="flex flex-col lg:inline-block">
                  <h5 class="mb-2 text-20 md:text-2xl font-semibold tracking-tight text-gray-900 dark:text-white">{{
                    step.title }}</h5>
                  <p class="text-14 font-normal text-gray-500 dark:text-gray-400 mb-5">{{ step.description }}</p>
                </div>
              </div>
              <template v-if="step.buttons">
                <div class="flex w-full md:w-fit lg:flex-wrap xl:flex-nowrap">
                  <router-link v-for="(button, buttonIndex) in step.buttons" :key="buttonIndex" :data-index="index"
                    :to="getRedirectLink(button)"
                    class="text-white text-14 md:text-base bg-pinkHover py-1 px-4 rounded font-semibold whitespace-pre mr-5 w-full md:w-100 inline-block md:block lg:inline-block text-center">{{
                      button.text }}</router-link>
                </div>
              </template>
              <template v-else>
                <router-link :to="step.buttonLink"
                  class="text-white text-14 md:text-base bg-pinkHover py-1 px-4 rounded font-semibold block md:inline-block text-center">{{
                    step.buttonText }}</router-link>
              </template>
            </div>
          </transition-group>
        </div>
      </div>

    </div>
    <div class="bg-black my-16 xl:h-826 overflow-hidden">
      <div class="lg:container mx-auto py-5 px-4 lg:px-12 2xl:px-40 ">
        <div class="flex justify-center w-full py-5">
          <div class="w-full relative">
            <!-- Tab buttons -->
            <div class="inline-flex mb-4 bg-secondarydark w-full lg:w-auto lg:w-100 p-1 overflow-auto">
              <button @click="darkactiveTab = 'tab1'"
                class="py-2 px-4 rounded mr-2 text-graylight font-semibold whitespace-pre"
                :class="{ 'bg-lightdark font-bold !text-white': darkactiveTab === 'tab1' }">Derivatives</button>
              <button @click="darkactiveTab = 'tab2'"
                class="py-2 px-4 rounded mr-2 text-graylight font-semibold whitespace-pre"
                :class="{ 'bg-lightdark font-bold !text-white': darkactiveTab === 'tab2' }">Finance</button>
              <button @click="darkactiveTab = 'tab3'"
                class="py-2 px-4 rounded mr-2 text-graylight font-semibold whitespace-pre"
                :class="{ 'bg-lightdark font-bold !text-white': darkactiveTab === 'tab3' }">Moments</button>
              <button @click="darkactiveTab = 'tab4'"
                class="py-2 px-4 rounded mr-2 text-graylight font-semibold whitespace-pre"
                :class="{ 'bg-lightdark font-bold !text-white': darkactiveTab === 'tab4' }">Cpoy Trading</button>
              <button @click="darkactiveTab = 'tab5'"
                class="py-2 px-4 rounded mr-2 text-graylight font-semibold whitespace-pre"
                :class="{ 'bg-lightdark font-bold !text-white': darkactiveTab === 'tab5' }">Web3</button>
              <button @click="darkactiveTab = 'tab6'"
                class="py-2 px-4 rounded mr-2 text-graylight font-semibold whitespace-pre"
                :class="{ 'bg-lightdark font-bold !text-white': darkactiveTab === 'tab6' }">Gate card</button>


            </div>
            <!-- Tab content -->
            <div class="mt-5 md:mt-10">
              <transition name="slide">
                <div v-if="darkactiveTab === 'tab1'" class="slide">
                  <div class="text-white rounded">
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                      <div class="flex flex-col justify-center order-2 lg:order-1 w-full">
                        <p class="text-24 lg:text-40 font-bold">Perpetual Futures</p>
                        <div class="lg:w-96 pt-6">
                          <ul>
                            <li class="flex items-start">
                              <img src="/assets/home/listdots.svg" alt="" width="32" class="mr-1 md:mr-3 md:mt-2">
                              <p class="text-20 text-graylight">Trade Bitcoin and other cryptos with leverage, both long
                                and
                                short</p>
                            </li>
                            <li class="flex items-start mt-6 ">
                              <img src="/assets/home/listdots.svg" alt="" width="32" class="mr-1 md:mr-3 md:mt-2">
                              <p class="text-20 text-graylight">Trade Bitcoin and other cryptos with leverage, both long
                                and
                                short</p>
                            </li>
                          </ul>
                          <div class="mt-16">
                            <a href="/"
                              class="text-white text-base bg-pinkHover py-2 px-4 rounded font-bold block lg:inline-block text-center">Trade
                              Now</a>
                          </div>
                        </div>
                      </div>
                      <div class="order-1 lg:order-2">
                        <div class="bg-video flex justify-center lg:block">
                          <video class="bg-video__content" autoplay muted loop width="600">
                            <source src="/assets/video/pre-future.mp4" type="video/mp4">
                          </video>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="darkactiveTab === 'tab2'"
                  class="slide relative before:bg-tab before:absolute before:top-30  before:end-30 before:h-30T before:w-30 before:blur-140b">
                  <div class="text-white rounded relative">
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                      <div class="flex flex-col justify-center order-2 lg:order-1 w-full">
                        <p class="text-24 lg:text-40 font-bold">Gate Finance</p>
                        <div class="lg:w-96 pt-6">
                          <ul>
                            <li class="flex items-start">
                              <img src="/assets/home/check.svg" alt="" width="24" class="mr-3 mt-2">
                              <p class="text-20 text-graylight">Various ways to earn interest on crypto</p>
                            </li>
                            <li class="flex items-start mt-6 ">
                              <img src="/assets/home/check.svg" alt="" width="24" class="mr-3 mt-2">
                              <p class="text-20 text-graylight">Best-in-class security infrastructure</p>
                            </li>
                            <li class="flex items-start mt-6 ">
                              <img src="/assets/home/check.svg" alt="" width="24" class="mr-3 mt-2">
                              <p class="text-20 text-graylight">Minimal barriers to entry</p>
                            </li>
                          </ul>
                          <div class="mt-16">
                            <a href="/"
                              class="text-white text-base bg-pinkHover py-2 px-4 rounded font-bold block lg:inline-block text-center">Trade
                              Now</a>
                          </div>
                        </div>
                      </div>
                      <div class="order-1 lg:order-2 lg:py-24">
                        <TabHover />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="darkactiveTab === 'tab3'"
                  class="slide relative before:bg-tab before:absolute before:top-30  before:end-30 before:h-30T before:w-30 before:blur-140b">
                  <div class="text-white rounded relative">
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                      <div class="flex flex-col justify-center order-2 lg:order-1 w-full">
                        <p class="text-24 lg:text-40 font-bold">Moments</p>
                        <div class="lg:w-96 pt-6">
                          <ul>
                            <li class="flex items-start">
                              <p class="text-20 text-graylight">Catch up with the latest updates of the crypto industry,
                                never miss anything in the world of crypto again</p>
                            </li>
                          </ul>
                          <div class="mt-16">
                            <a href="/"
                              class="text-white text-base bg-pinkHover py-2 px-4 rounded-md font-bold block lg:inline-block text-center">Read
                              the Latest News</a>
                          </div>
                        </div>
                      </div>
                      <div class="order-1 lg:order-2 lg:py-24 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4">
                        <div
                          class="border border-lightdark rounded-md flex flex-col items-center h-474 bg-bgdarkColor justify-between hover:shadow-tabcustom hover:bg-transparent py-4 pl-4">
                          <video class="bg-video__content rounded md:w-304 lg:w-full" autoplay muted loop>
                            <source src="/assets/video/moments-1.mp4" type="video/mp4">
                          </video>
                          <div class="flex items-start w-full">
                            <p class="text-20 mt-6 font-semibold text-start mb-2">News</p>
                          </div>
                        </div>

                        <div class="grid grid-cols-1 lg:grid-rows-2 gap-4 h-474">
                          <div
                            class="border border-lightdark rounded-md flex flex-col items-center bg-bgdarkColor justify-between hover:shadow-tabcustom hover:bg-transparent p-4">
                            <video class="bg-video__content rounded md:w-304 lg:w-full" autoplay muted loop>
                              <source src="/assets/video/moments-2.mp4" type="video/mp4">
                            </video>
                            <div class="flex items-start w-full">
                              <p class="text-20 mt-6 font-semibold text-start mb-2">Gate Post</p>
                            </div>
                          </div>
                          <div
                            class="border border-lightdark rounded-md flex flex-col items-center bg-bgdarkColor justify-between hover:shadow-tabcustom hover:bg-transparent py-4 pl-4">
                            <video class="bg-video__content rounded md:w-304 lg:w-full" autoplay muted loop>
                              <source src="/assets/video/moments-3.mp4" type="video/mp4">
                            </video>
                            <div class="flex items-start w-full">
                              <p class="text-20 mt-6 font-semibold text-start mb-2">Live Stream</p>
                            </div>
                          </div>

                        </div>


                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="darkactiveTab === 'tab4'"
                  class="slide slide relative before:bg-tab before:absolute before:top-30  before:end-30 before:h-30T before:w-30 before:blur-140b">
                  <div class="text-white rounded relative">
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                      <div class="flex flex-col justify-center order-2 lg:order-1 w-full">
                        <p class="text-24 lg:text-40 font-bold">Copy Trading</p>
                        <div class="lg:w-96 pt-6">
                          <ul>
                            <li class="flex items-start">
                              <p class="text-20 text-graylight">{{BRAND_NAME()}} Copy Trading provides you with a variety of
                                high-quality trading signals, making it easy for you to grow your wealth</p>
                            </li>
                          </ul>
                          <div class="mt-16">
                            <a href="/"
                              class="text-white text-base bg-pinkHover py-2 px-4 rounded-md font-bold block lg:inline-block text-center">Explore</a>
                          </div>
                        </div>
                      </div>
                      <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4  order-1 lg:order-2 lg:py-24 ">
                        <div
                          class=" relative h-320 lg:inline-block bg-bgdarkColor border border-lightdark hover:shadow-tabcustom transition-all hover:bg-transparent rounded-lg dark:bg-gray-800 dark:border-gray-700 group p-4">
                          <img src="/assets/home/bot-copy-trading.webp" alt="" class="">
                          <div class="md:w-full">
                            <div class="flex justify-between">
                              <div class="flex">
                                <a href="#">
                                  <h5 class="text-20 font-semibold dark:text-white mt-8 mb-2">Bot Copy Trading</h5>
                                </a>
                              </div>
                            </div>
                            <p class="mb-3 mt-1 text-12 text-graylight dark:text-gray-400">The most comprehensive
                              platform
                              for creating and copying bots.</p>
                            <a href="/"
                              class="text-white text-xs bg-pinkHover py-1.5 px-4 rounded opacity-0 font-bold group-hover:opacity-100 hidden lg:inline-block absolute bottom-4 right-4">Go</a>
                          </div>
                        </div>

                        <div
                          class=" relative h-320 lg:inline-block bg-bgdarkColor border border-lightdark hover:shadow-tabcustom transition-all hover:bg-transparent rounded-lg dark:bg-gray-800 dark:border-gray-700 group p-4">
                          <img src="/assets/home/future-copy-trading.webp" alt="" class="">
                          <div class="md:w-full">
                            <div class="flex justify-between">
                              <div class="flex">
                                <a href="#">
                                  <h5 class="text-20 font-semibold dark:text-white mt-8 mb-2">Futures Copy Trading</h5>
                                </a>
                              </div>

                            </div>
                            <p class="mb-3 mt-1 text-12 text-graylight dark:text-gray-400">Aggregate signals of top lead
                              traders for more stable and higher returns.</p>
                            <a href="/"
                              class="text-white text-xs bg-pinkHover py-1.5 px-4 rounded font-bold opacity-0 group-hover:opacity-100 hidden lg:inline-block absolute bottom-4 right-4">Go</a>
                          </div>
                        </div>
                        <div
                          class=" relative h-320 lg:inline-block bg-bgdarkColor border border-lightdark hover:shadow-tabcustom transition-all hover:bg-transparent rounded-lg dark:bg-gray-800 dark:border-gray-700 group p-4">
                          <img src="/assets/home/spot-copy-trading.webp" alt="" class="">
                          <div class="md:w-full">
                            <div class="flex justify-between">
                              <div class="flex">
                                <a href="#">
                                  <h5 class="text-20 font-semibold dark:text-white mt-8 mb-2">Spot Copy Trading</h5>
                                </a>
                              </div>
                            </div>
                            <p class="mb-3 mt-1 text-12 text-graylight dark:text-gray-400">Platform with the largest
                              number
                              of crypto, featuring top spot investors.</p>
                            <a href="/"
                              class="text-white text-xs bg-pinkHover py-1.5 px-4 rounded font-bold opacity-0 group-hover:opacity-100 hidden lg:inline-block absolute bottom-4 right-4">Go</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="darkactiveTab === 'tab5'"
                  class="slide slide relative before:bg-tab before:absolute before:top-30  before:end-30 before:h-30T before:w-30 before:blur-140b">
                  <div class="text-white rounded relative">
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                      <div class="flex flex-col justify-center order-2 lg:order-1 w-full">
                        <p class="text-24 lg:text-40 font-bold">Web3</p>
                        <div class="lg:w-96 pt-6">
                          <ul>
                            <li class="flex items-start">
                              <p class="text-20 text-graylight">Decentralized wallets, cross-chain swapping and trading
                                of
                                cryptocurrencies, multi-chain NFT marketplace and DApps for you</p>
                            </li>
                          </ul>
                          <div class="mt-16">
                            <a href="/"
                              class="text-white text-base bg-pinkHover py-2 px-4 rounded-md font-bold block lg:inline-block text-center">Explore
                              Web3 Now</a>
                          </div>
                        </div>
                      </div>
                      <div class="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4  order-1 lg:order-2 lg:py-24 ">
                        <div
                          class="relative h-320 lg:inline-block bg-bgdarkColor border border-lightdark hover:shadow-tabcustom transition-all hover:bg-transparent rounded-lg dark:bg-gray-800 dark:border-gray-700 group p-4">
                          <img src="/assets/home/bot-copy-trading.webp" alt="" class="">
                          <div class="md:w-full">
                            <div class="flex justify-between">
                              <div class="flex">
                                <a href="#">
                                  <h5 class="text-20 font-semibold dark:text-white mt-8 mb-2">Dashboard</h5>
                                </a>
                              </div>
                            </div>
                            <p class="mb-3 mt-1 text-12 text-graylight dark:text-gray-400">View and manage your asset on
                              various chains.</p>
                            <a href="/"
                              class="text-white text-xs bg-pinkHover py-1.5 px-4 rounded opacity-0 font-bold group-hover:opacity-100 hidden lg:inline-block absolute bottom-4 right-4">Go</a>
                          </div>
                        </div>

                        <div
                          class="relative h-320 lg:inline-block bg-bgdarkColor border border-lightdark hover:shadow-tabcustom transition-all hover:bg-transparent rounded-lg dark:bg-gray-800 dark:border-gray-700 group p-4">
                          <img src="/assets/home/future-copy-trading.webp" alt="" class="">
                          <div class="md:w-full">
                            <div class="flex justify-between">
                              <div class="flex">
                                <a href="#">
                                  <h5 class="text-20 font-semibold dark:text-white mt-8 mb-2">Earn</h5>
                                </a>
                              </div>

                            </div>
                            <p class="mb-3 mt-1 text-12 text-graylight dark:text-gray-400">Aggregate interest-bearing
                              products from multiple DeFi protocols.</p>
                            <a href="/"
                              class="text-white text-xs bg-pinkHover py-1.5 px-4 rounded font-bold opacity-0 group-hover:opacity-100 hidden lg:inline-block absolute bottom-4 right-4">Go</a>
                          </div>
                        </div>
                        <div
                          class="relative h-320 lg:inline-block bg-bgdarkColor border border-lightdark hover:shadow-tabcustom transition-all hover:bg-transparent rounded-lg dark:bg-gray-800 dark:border-gray-700 group p-4">
                          <img src="/assets/home/spot-copy-trading.webp" alt="" class="">
                          <div class="md:w-full">
                            <div class="flex justify-between">
                              <div class="flex">
                                <a href="#">
                                  <h5 class="text-20 font-semibold dark:text-white mt-8 mb-2">Trade</h5>
                                </a>
                              </div>
                            </div>
                            <p class="mb-3 mt-1 text-12 text-graylight dark:text-gray-400">Supporting multiple Dex
                              trading,
                              cross-chain swap.</p>
                            <a href="/"
                              class="text-white text-xs bg-pinkHover py-1.5 px-4 rounded font-bold opacity-0 group-hover:opacity-100 hidden lg:inline-block absolute bottom-4 right-4">Go</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="darkactiveTab === 'tab6'"
                  class="slide slide relative before:bg-tab before:absolute before:top-30  before:end-30 before:h-30T before:w-30 before:blur-140b">
                  <div class="text-white rounded relative xl:pt-[195px]">
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                      <div class="flex flex-col justify-center order-2 lg:order-1 w-full">
                        <p class="text-24 lg:text-40 font-bold">Gate Card</p>
                        <div class="lg:w-96 pt-6">
                          <ul>
                            <li class="flex items-start">
                              <p class="text-20 text-graylight">Visa Debit Card, Enjoy 1% Cashback in USDT</p>
                            </li>
                          </ul>
                          <div class="mt-16">
                            <a href="/"
                              class="text-white text-base bg-pinkHover py-2 px-4 rounded-md font-bold block lg:inline-block text-center">Apply
                              Now</a>
                          </div>
                        </div>
                      </div>
                      <div class="grid grid-cols-2 gap-4  order-1 lg:order-2">
                        <div
                          class="md:flex md:items-center relative lg:inline-block  transition-all hover:bg-transparent rounded-lg dark:bg-gray-800 dark:border-gray-700 group p-4 h-258 w-398">
                          <div
                            class="bg-visaCard h-258 w-398 bg-no-repeat bg-cente bg-cover shadow-visatabcustom z-50 absolute right-10 rounded-lg">
                          </div>
                        </div>

                        <div
                          class="md:flex md:items-center relative lg:inline-block  transition-all hover:bg-transparent rounded-lg dark:bg-gray-800 dark:border-gray-700 group p-4">
                          <img src="/assets/home/visa-card-2.webp" alt="" class="w-398 h-258">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else-if="darkactiveTab === 'tab7'"
                  class="slide slide relative before:bg-tab before:absolute before:top-30  before:end-30 before:h-30T before:w-30 before:blur-140b">
                  <div class="text-white rounded relative md:pt-[195px]">
                    <div class="grid grid-cols-1 lg:grid-cols-2 gap-4">
                      <div class="flex flex-col justify-center order-2 lg:order-1 w-full">
                        <p class="text-24 lg:text-40 font-bold">Gate Pay</p>
                        <div class="lg:w-96 pt-6">
                          <ul>
                            <li class="flex items-start">
                              <p class="text-20 text-graylight">Send or receive cryptocurrencies as a merchant or an
                                individual anywhere anytime</p>
                            </li>
                          </ul>
                          <div class="mt-16">
                            <a href="/"
                              class="text-white text-base bg-pinkHover py-2 px-4 rounded-md font-bold block lg:inline-block text-center">More
                              about Gate Pay</a>
                          </div>
                        </div>
                      </div>
                      <div class="grid grid-cols-2 gap-4  order-1 lg:order-2">

                        <div
                          class="md:flex md:items-center relative lg:inline-block  transition-all hover:bg-transparent rounded-lg dark:bg-gray-800 dark:border-gray-700 group p-4">
                          <img src="/assets/home/gate-pay-2.webp" alt="" class="w-320 h-258">
                        </div>
                        <div
                          class="md:flex md:items-center relative lg:inline-block  transition-all hover:bg-transparent rounded-lg dark:bg-gray-800 dark:border-gray-700 group p-4 h-258 w-398">
                          <div
                            class="bg-gatepay h-258 w-398 bg-no-repeat bg-cente bg-cover shadow-visatabcustom z-50 absolute right-10 rounded-lg">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- crypto lifecycle -->
    <div class="lg:container mx-auto py-5 px-4 lg:px-12 2xl:px-40 px-0 px-lg-5">
      <div class="lifestyle px-0 px-lg-5">
        <div class="mb-6">
          <p class="text-24 lg:text-40 font-bold">Crypto Lifestyle</p>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4 group">
          <div
            class="md:flex md:items-center lg:inline-block bg-main-gray border border-gray-100 hover:shadow-custom transition-all hover:bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700 group">
            <video class="bg-video__content rounded md:w-304 lg:w-full" autoplay muted loop>
              <source src="/assets/video/gate-post.mp4" type="video/mp4">
            </video>
            <div class="p-5 md:w-full">
              <div class="flex justify-between">
                <div class="flex">
                  <img src="/assets/home/life-style-post.svg" alt="" width="20" class=" mr-2">
                  <a href="#">
                    <h5 class="text-20 font-bold text-gray-900 dark:text-white">Gate post</h5>
                  </a>
                </div>
                <a href="/"
                  class="text-white text-base bg-pinkHover py-1.5 px-4 rounded opacity-0 font-bold group-hover:opacity-100 hidden lg:block">Go</a>
              </div>
              <p class="mb-3 mt-1 text-graylight dark:text-gray-400">Share Your Crypto Life with Traders.</p>
              <a href="/"
                class="text-white text-base bg-pinkHover py-1.5 px-4 rounded font-bold  w-full inline-block lg:hidden text-center">Go</a>

            </div>
          </div>

          <div
            class="md:flex md:items-center lg:inline-block bg-main-gray border  border-gray-100 hover:shadow-custom transition-all hover:bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700 group">
            <video class="bg-video__content rounded md:w-304 lg:w-full" autoplay muted loop>
              <source src="/assets/video/live-stream.mp4" type="video/mp4">
            </video>
            <div class="p-5 md:w-full">
              <div class="flex justify-between">
                <div class="flex">
                  <img src="/assets/home/life-style-post.svg" alt="" width="20" class=" mr-2">
                  <a href="#">
                    <h5 class="text-20 font-bold text-gray-900 dark:text-white">Live Stream</h5>
                  </a>
                </div>
                <a href="/"
                  class="text-white text-base bg-pinkHover py-1.5 px-4 rounded font-bold opacity-0 group-hover:opacity-100 hidden lg:block">Go</a>

              </div>
              <p class="mb-3 mt-1 text-graylight dark:text-gray-400">Live Interaction with Zero Time Difference.</p>
              <a href="/"
                class="text-white text-base bg-pinkHover py-1.5 px-4 rounded font-bold  w-full inline-block lg:hidden text-center">Go</a>
            </div>
          </div>
          <div
            class="md:flex md:items-center lg:inline-block bg-main-gray border  border-gray-100 hover:shadow-custom transition-all hover:bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700 group">
            <video class="bg-video__content rounded md:w-304 lg:w-full" autoplay muted loop>
              <source src="/assets/video/chatroom.mp4" type="video/mp4">
            </video>
            <div class="p-5 md:w-full">
              <div class="flex justify-between">
                <div class="flex">
                  <img src="/assets/home/life-style-post.svg" alt="" width="20" class=" mr-2">
                  <a href="#">
                    <h5 class="text-20 font-bold text-gray-900 dark:text-white">Chatroom</h5>
                  </a>
                </div>
                <a href="/"
                  class="text-white text-base bg-pinkHover py-1.5 px-4 rounded font-bold opacity-0 group-hover:opacity-100 hidden lg:block">Go</a>
              </div>
              <p class="mb-3 mt-1 text-graylight dark:text-gray-400">Chat with crypto traders</p>
              <a href="/"
                class="text-white text-base bg-pinkHover py-1.5 px-4 rounded font-bold  w-full inline-block lg:hidden text-center">Go</a>

            </div>
          </div>
        </div>
      </div>

      <div class="px-0 px-lg-5">
        <div class="reviews mt-6 bg-main-gray px-6 py-8 rounded-lg border border-gray-100">
          <div class="lg:flex">
            <div class="flex-1 group">
              <carousel :items-to-show="1.50" :breakpoints="breakpoints2" :transition="500" :wrap-around="true">
                <slide v-for="(option, index) in reviewoptions" :key="index">
                  <div
                    class="bg-white main-img px-6 mb-4 rounded-lg flex items-center justify-center flex-col py-10 md:mx-6">
                    <div>
                      <img :src="`/assets/home/${option.img}`" :alt="option.img"
                        class="rounded-full w-80 h-80 object-cover" />
                    </div>
                    <div>
                      <p class="fs-5 text-graylight mt-3 mb-6 font-medium">{{ option.name }}</p>
                    </div>
                    <div class="w-full">
                      <p class="fs-5 fw-normal text-start">{{ option.title }}</p>
                    </div>
                  </div>
                  <div class="custom-pagi">
                    <!-- <pagination />  -->
                  </div>
                </slide>
                <template #addons="{ slidesCount }">
                  <div>
                    <Navigation v-if="slidesCount > 1"
                      class="my-auto bg-btn-gray bg-opacity-75 rounded px-1 py-1 opacity-0 group-hover:opacity-100">
                    </Navigation>
                  </div>
                </template>
              </carousel>

            </div>
            <div class="lg:w-96 lg:pl-16 md:flex lg:inline-block">
              <div class="flex items-center justify-center">
                <img src="/assets/home/media-share-pic.png" alt="" width="" class="">
              </div>
              <div class="lg:w-full flex items-center lg:justify-between flex-col md:w-96 lg:ml-0">
                <div class="w-full flex items-center justify-between  py-10">
                  <img src="/assets/home/facebook.svg" alt="" width="32">
                  <img src="/assets/home/youtube.svg" alt="" width="32">
                  <img src="/assets/home/telegram.svg" alt="" width="32">
                  <img src="/assets/home/envelope-solid.svg" alt="" width="32">
                  <img src="/assets/home/square-instagram.svg" alt="" width="32">
                </div>
                <div class="w-full">
                  <button class="w-full"><a href="/"
                      class="text-white text-base bg-pinkHover py-1.5 px-4 rounded font-semibold w-full block">Join Gate
                      Community Now</a></button>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="what-say py-16 px-0 px-lg-5">
        <div class="mb-8 flex justify-between">
          <p class="text-24 lg:text-40 font-bold">What the Press says</p>
          <img src="/assets/home/what-say.svg" alt="" width="100" class="hidden md:block">
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div
            class="md:flex lg:inline-block bg-white border border-gray-200 rounded-lg hover:shadow-custom hover:bg-main-gray transition-all  dark:bg-gray-800 dark:border-gray-700">
            <img src="/assets/home/press-1.png" alt="" width="" class="rounded-lg md:w-304 lg:w-full">
            <div class="p-5">
              <a href="#">
                <h5 class="text-20 font-bold text-gray-900 dark:text-white line-clamp-2">Crypto Exchange {{BRAND_NAME()}} to Help
                  Busan, South Korea, Build Blockchain Infrastructure</h5>
              </a>
              <p class="mb-3 mt-1 text-graylight dark:text-gray-400 line-clamp-2">The firm joins Binance, Huobi and FTX
                in
                inking paperwork with the city as it develops its nascent crypto market. Crypto exchange {{BRAND_NAME()}} signed
                a
                memorandum of understanding (MoU) on Wednesday with the South Korean city of Busan to help develop
                blockchain infrastructure in the city.</p>
              <div class="mt-8 mb-3">
                <img src="/assets/home/coin-desk.png" alt="" width="170" height="32" class="rounded-lg">
              </div>
            </div>
          </div>
          <div
            class="md:flex lg:inline-block bg-white border border-gray-200 rounded-lg hover:shadow-custom hover:bg-main-gray transition-all  dark:bg-gray-800 dark:border-gray-700">
            <img src="/assets/home/press-2.png" alt="" width="" class="rounded-lg md:w-304 lg:w-full">
            <div class="p-5">
              <a href="#">
                <h5 class="text-20 font-bold text-gray-900 dark:text-white line-clamp-2">{{BRAND_NAME()}} CEO Dr. Lin Han on The
                  Future of Security</h5>
              </a>
              <p class="mb-3 mt-1 text-graylight dark:text-gray-400 line-clamp-2">{{BRAND_NAME()}} aims to have a very strong
                security system as an ecosystem – not only for CEX, but also for Web3. We want to figure out everything
                that’s possible to attack you.</p>
              <div class="mt-8 mb-3">
                <img src="/assets/home/be-crypto.png" alt="" width="170" height="32" class="rounded-lg">
              </div>
            </div>
          </div>
          <div
            class="md:flex lg:inline-block bg-white border border-gray-200 rounded-lg hover:shadow-custom hover:bg-main-gray transition-all  dark:bg-gray-800 dark:border-gray-700">
            <img src="/assets/home/press-3.png" alt="" width="" class="rounded-lg md:w-304 lg:w-full">
            <div class="p-5">
              <a href="#">
                <h5 class="text-20 font-bold text-gray-900 dark:text-white line-clamp-2">{{BRAND_NAME()}}’s Proof of Reserves
                  Report
                  Reveals $4.3B in Assets with 115% Reserve Ratio for 171 Assets</h5>
              </a>
              <p class="mb-3 mt-1 text-graylight dark:text-gray-400 line-clamp-2">{{BRAND_NAME()}}, a leading crypto exchange and
                Web3 innovator, has released its January 2024 Proof of Reserves (PoR), verifiably demonstrating $4.3
                billion
                in total held assets.</p>
              <div class="mt-8 mb-3">
                <img src="/assets/home/metaverse-post.png" alt="" width="135" height="32" class="rounded-lg">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="today-market-table lg:py-6 md:py-2 mt-5 md:mt-10 px-0 px-lg-5">
        <div class="flex items-center justify-between">
          <p class="text-16 md:text-24 lg:text-40 font-semibold mb-3">Trade Crypto Anywhere Anytime</p>
          
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2">
          <div class="md:flex lg:inline-block md:items-center md:justify-center md:w-full">
            <img src="/assets/home/crypto-anywhere.png" alt="Trade Crypto Anywhere Anytime" width=""
              class="w-full md:w-474 lg:w-608">
          </div>
          <div class="flex flex-col justify-center lg:ml-32">
            <div class="scan-card p-2 bg-main-gray border border-gray-200 rounded-lg flex items-center mt-16">
              <img src="/assets/home/scan.png" alt="Scan" width="126" height="126">
              <div class=" ml-10">
                <p class="text-20 font-bold text-gray-900 dark:text-white line-clamp-2">Scan QR Code</p>
                <p class="mt-2  dark:text-gray-400 line-clamp-2">Download {{BRAND_NAME()}} App for Android / iOS</p>
              </div>
            </div>
            <div class="flex items-center justify-between mt-5 w-">
              <div
                class="p-1 hover:bg-main-gray hover:shadow rounded h-24 w-24 flex flex-col justify-center items-center px-2 transition-all hover:text-blue-500 crypto-use">
                <img src="/assets/home/app-store.png" alt="" width="32" height="32">
                <p class="text-14">App Store</p>
              </div>
              <div
                class="p-1 hover:bg-main-gray hover:shadow rounded h-24 w-24 flex flex-col justify-center items-center px-2 transition-all hover:text-blue-500 crypto-use">
                <img src="/assets/home/android.png" alt="" width="32" height="32">
                <p class="text-14">Android</p>
              </div>
              <div
                class="p-1 hover:bg-main-gray hover:shadow rounded h-24 w-24 flex flex-col justify-center items-center px-2 transition-all hover:text-blue-500 crypto-use">
                <img src="/assets/home/google-play.png" alt="" width="32" height="32">
                <p class="text-14">Google play</p>
              </div>
            </div>
            <div class="flex items-center justify-between mt-5 w-full">
              <div
                class="p-1 hover:bg-main-gray hover:shadow rounded h-24 w-24 flex flex-col justify-center items-center px-2 transition-all hover:text-blue-500 crypto-use">
                <img src="/assets/home/mac-os.png" alt="" width="32" height="32">
                <p class="text-14">Mac OS</p>
              </div>
              <div
                class="p-1 hover:bg-main-gray hover:shadow rounded h-24 w-24 flex flex-col justify-center items-center px-2 transition-all hover:text-blue-500 crypto-use">
                <img src="/assets/home/windows.png" alt="" width="32" height="32">
                <p class="text-14">Windows</p>
              </div>
              <div
                class="p-1 hover:bg-main-gray hover:shadow rounded h-24 w-24 flex flex-col justify-center items-center px-2 transition-all hover:text-blue-500 crypto-use">
                <img src="/assets/home/api.png" alt="" width="32" height="32">
                <p class="text-14">API</p>
              </div>
            </div>

          </div>

        </div>

      </div>

      <div class="today-market-table py-6 mt-5 md:mt-10 px-0 px-lg-5">
        <div class="flex items-center justify-between py-4 lg:py-0">
          <p class="text-24 lg:text-40 font-semibold mb-3">FAQ about {{BRAND_NAME()}}</p>
         
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div class="bg-white rounded-xl lg:my-12">
            <details class="p-8 bg-main-gray group  border border-gray-100 rounded-lg ">
              <summary class="flex items-center justify-between cursor-pointer">
                <h5 class="text-medium md:text-lg font-semibold text-gray-900 ">
                  How does a crypto exchange like {{BRAND_NAME()}} work?
                </h5>

                <span class="relative flex-shrink-0 ml-1.5 w-7 h-7">
                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="absolute inset-0 w-7 h-7 opacity-100 group-open:opacity-0" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>

                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="absolute inset-0 w-7 h-7 opacity-0 group-open:opacity-100" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
              </summary>

              <p class="lg:mt-4 leading-relaxed text-gray-700 font-semibold">
                {{BRAND_NAME()}} is a crypto exchange where you can buy, sell, and trade 1,700+ cryptocurrencies. Once you
                complete
                the registration and KYC process, you can simply deposit funds and start buying cryptocurrencies.
                {{BRAND_NAME()}}
                also provides you with an exchange wallet to manage your crypto investments, as well as live crypto
                market
                data so that you can analyze the market trend.
              </p>
            </details>

            <details class="p-8 bg-main-gray group  border border-gray-100 rounded-lg mt-6">
              <summary class="flex items-center justify-between cursor-pointer">
                <h5 class="text-medium md:text-lg font-semibold text-gray-900">
                  What products does {{BRAND_NAME()}} provide?
                </h5>

                <span class="relative flex-shrink-0 ml-1.5 w-7 h-7">
                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="absolute inset-0 w-7 h-7 opacity-100 group-open:opacity-0" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>

                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="absolute inset-0 w-7 h-7 opacity-0 group-open:opacity-100" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
              </summary>

              <p class="lg:mt-4 leading-relaxed text-gray-700 font-semibold">
                Apart from buying, selling and trading crypto, {{BRAND_NAME()}} also provides users with exchange wallets to
                manage
                digital assets, listing of new crypto airdrops via Gate Startup and live crypto prices for your
                analysis.
                Further, {{BRAND_NAME()}} also extends its services to include decentralized web3 products such as web3 wallets,
                staking via LSD protocols, multichain NFTs and DApps. From an educational perspective, you can also
                learn
                more about the crypto and Web3 world on Gate Learn, and from time to time check out the price prediction
                of
                the crypto market as a whole.
              </p>
            </details>
          </div>
          <div class="bg-white rounded-xl lg:my-12">
            <details class="p-8 bg-main-gray group  border border-gray-100 rounded-lg ">
              <summary class="flex items-center justify-between cursor-pointer">
                <h5 class="text-medium md:text-lg font-semibold text-gray-900">
                  How do I buy crypto on {{BRAND_NAME()}}?
                </h5>

                <span class="relative flex-shrink-0 ml-1.5 w-7 h-7">
                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="absolute inset-0 w-7 h-7 opacity-100 group-open:opacity-0" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>

                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="absolute inset-0 w-7 h-7 opacity-0 group-open:opacity-100" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
              </summary>

              <p class="mt-4 leading-relaxed text-gray-700 font-semibold">
                There are a few ways you can buy crypto on {{BRAND_NAME()}}. One of the methods include buying via the spot
                markets
                on {{BRAND_NAME()}}. Further, you can also take advantage of the short term market fluctuation by trading
                leveraged
                products and/or derivatives. This way, you can amplify gains if the market moves in the same direction
                as
                per your prediction. For a complete guide, check out how to buy crypto on {{BRAND_NAME()}}. More advanced users
                can
                also choose to connect the wallet to exchange one cryptocurrency for another using the web3 swap
                feature.
              </p>
            </details>

            <details class="p-8 bg-main-gray group  border border-gray-100 rounded-lg mt-6">
              <summary class="flex items-center justify-between cursor-pointer">
                <h5 class="text-medium md:text-lg font-semibold text-gray-900">
                  Is {{BRAND_NAME()}} a safe cryptocurrency exchange?
                </h5>

                <span class="relative flex-shrink-0 ml-1.5 w-7 h-7">
                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="absolute inset-0 w-7 h-7 opacity-100 group-open:opacity-0" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>

                  <svg xmlns="http://www.w3.org/2000/svg"
                    class="absolute inset-0 w-7 h-7 opacity-0 group-open:opacity-100" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor" stroke-width="1.5">
                    <path stroke-linecap="round" stroke-linejoin="round"
                      d="M15 12H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </span>
              </summary>

              <p class="mt-4 leading-relaxed text-gray-700 font-semibold">
                As a leading crypto exchange, {{BRAND_NAME()}} has a strong track record since 2013 supporting over 1,700
                cryptocurrencies so far. Measures relating to proof of reserves have also been implemented back in 2015.
                With consistently above average liquidity and trading volume, as well as the top notch security
                measures,
                users can trust that {{BRAND_NAME()}} is a safe and reliable crypto exchange.
              </p>
            </details>
          </div>
        </div>

      </div>
      <div class="flex items-center justify-center py-16 px-0 px-lg-5">
        <p class=" text-18 mdtext-24 lg:text-40 font-bold text-center md:hidden lg:block">The only crypto exchange to
          buy
          and <br>
          sell
          1700+ cryptocurrencies</p>
        <p class="text-24 lg:text-40 font-bold text-center hidden md:block lg:hidden">The only crypto exchange to buy
          and
          sell 1700+ cryptocurrencies</p>

      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import TabHover from '@/components/TabHover.vue';
import CryptoMarketVIew from '@/components/CryptoMarketVIew.vue';
import { gsap } from "gsap";
import ApiClass from '@/api';
export default {
  name: 'HomeView',
  components: {
    Carousel,
    Slide,
    Navigation,
    TabHover,
    CryptoMarketVIew,
  },
  data() {
    return {
      showAnimation: false,
      darkactiveTab: 'tab1',
      breakpoints1: {
        0: {
          itemsToShow: 1,
          snapAlign: "center",
          margin: 10,
        },
        700: {
          itemsToShow: 2.5,
          snapAlign: "center",
          margin: 10,
        },
        // 1024 and up
        1024: {
          itemsToShow: 3.5,
          snapAlign: "center",
          margin: 10,
        }
      },
      breakpoints2: {
        0: {
          itemsToShow: 1,
          snapAlign: "center",
          margin: 10,
        },
        700: {
          itemsToShow: 1,
          snapAlign: "start",
          margin: 10,
        },
        1024: {
          itemsToShow: 1,
          snapAlign: "start",
          margin: 10,
        },
        1500: {
          itemsToShow: 2,
          snapAlign: "start",
          margin: 10,
        }
      },
      breakpoints3: {
        0: {
          itemsToShow: 1,
          snapAlign: "center",
          margin: 10,
          vertical: true,

        },
        700: {
          itemsToShow: 1,
          snapAlign: "center",
          margin: 10,
          vertical: true,

        },
        1024: {
          itemsToShow: 1,
          snapAlign: "center",
          margin: 10,
          vertical: true,

        }
      },
      activityList:[],
      steps: [
        {
          "title": "01. Create Account",
          "image": "/assets/home/step-1.svg",
          "description": "Sign up and complete beginner tasks, win up to $6666",
          "buttonText": "Sign Up",
          "buttonLink": "/signup"
        },
        {
          "title": "02. Fund Your Account",
          "image": "/assets/home/step-2.svg",
          "description": "Add funds to your account to start your crypto investment journey.",
          "buttons": [
            {
              "text": "Deposit Crypto",
              "link": "/deposit/USDT",
              "route": "/login"
            },
            {
              "text": "Buy Crypto",
              "link": "/p2p",
              "route": "/login"
            }
          ]
        },
        {
          "title": "03. Start Trading",
          "image": "/assets/home/step-3.svg",
          "description": "Sell, buy or copy trade crypto and explore more.",
          "buttonText": "Trade",
          "buttonLink": "/spot/BTCUSDT"
        }
      ],
      reviewoptions: [
        {
          img: 'activities-1.png',
          name: 'Binner, Google User',
          title: `🔥🔥🔥 Wisewaytec is the best exchange app I have ever used. The interface is simple to operate and the customer service is quick. Some interesting activities and benefits are often presented!`,

        },
        {
          img: 'activities-2.png',
          name: 'Binner, Google User',
          title: `🔥🔥🔥 Wisewaytec is the best exchange app I have ever used. The interface is simple to operate and the customer service is quick. Some interesting activities and benefits are often presented!`,

        },
        {
          img: 'activities-3.png',
          name: 'Binner, Google User',
          title: `🔥🔥🔥Wisewaytec is the best exchange app I have ever used. The interface is simple to operate and the customer service is quick. Some interesting activities and benefits are often presented!`,

        },
        {
          img: 'activities-4.png',
          name: 'Binner, Google User',
          title: `🔥🔥🔥 Wisewaytec is the best exchange app I have ever used. The interface is simple to operate and the customer service is quick. Some interesting activities and benefits are often presented!`,

        },
        {
          img: 'activities-5.png',
          name: 'Binner, Google User',
          title: `🔥🔥🔥 Wisewaytec is the best exchange app I have ever used. The interface is simple to operate and the customer service is quick. Some interesting activities and benefits are often presented!`,

        },

      ],
      mainoptions: [
        {
          img: 'product-1.png',
          name: 'Binner, Google User',

        },
        {
          img: 'product-2.png',
          name: 'Binner, Google User',

        },
        {
          img: 'product-3.png',
          name: 'Binner, Google User',

        },
      ],
      email: '',
      isLoggedIn: false,
    };
  },
  mounted() {
    const items = gsap.utils.toArray(".item");
    const tl = gsap.timeline();

    items.forEach(item => {
      tl.from(item.querySelector(".first"), {
        opacity: 0,
        y: 50,
      })
        .from(item.querySelector(".second"), {
          opacity: 0,
          x: 50,
        })
    });

    setInterval(() => {
      this.showAnimation = !this.showAnimation;
    }, 1000);
    this.getActivites();
  },
  methods: {
    gotoSignup() {
      if (this.email) {
        localStorage.setItem('homePageEmail', JSON.stringify(this.email));
      }
      this.$router.push('/signup')
    },
    beforeEnter(el) {
      el.style.opacity = 0;
      el.style.transform = 'translateY(100px)';
    },
    enter(el, done) {
      gsap.to(el, {
        opacity: 1,
        y: 0,
        duration: 0.8,
        onComplete: done,
        delay: el.dataset.index * 0.2
      });
    },
    getRedirectLink(button) {
      this.isLoggedIn = localStorage.getItem("token") || false;
      if (this.isLoggedIn) {
        return button.link;
      } else {
        return button.route;
      }
    },
    async getActivites() {
      try {
        const allBanners = await ApiClass.getAdminRequest('admin/banner/get', false);
        if (allBanners.status_code == "1") {
          this.activityList=allBanners.data;
          return;
        }
      } catch (error) {
        return this.failed(error.message);
      }
    }
  },

}
</script>

<style>
@keyframes rollIn {
  0% {
    transform: translateY(20px);
  }

  100% {
    transform: translateY(0%);
  }
}


.animate-roll-in {
  animation: rollIn 1s ease-in-out forwards;
}

.crypto-use:hover img {
  filter: brightness(0) saturate(100%) invert(28%) sepia(97%) saturate(3789%) hue-rotate(222deg) brightness(92%) contrast(97%);
}

.bg-visaCard {
  background: url('../../public/assets/home/visa-card.webp');
}

.bg-gatepay {
  background: url('../../public/assets/home/gate-pay.webp');
}
</style>
